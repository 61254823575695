import React from "react";

import { Grid, Icon } from "semantic-ui-react";
import { Carousel } from "react-responsive-carousel";

import PaymentMethods from "../PaymentMethods";

const StylePayments = (props) => {
  return (
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <Grid className="grapperPayments ui-computer">
        <PaymentMethods
          paymentType={props.state.paymentType}
          type="monthly"
          quots={props.quots}
          changeMethod={(type) => props.onChangeState(type, "paymentType")}
        />

        <PaymentMethods
          paymentType={props.state.paymentType}
          type="weekly"
          quots={props.quots}
          changeMethod={(type) => props.onChangeState(type, "paymentType")}
        />

        <PaymentMethods
          paymentType={props.state.paymentType}
          type="weeklyPlus"
          quots={props.quots}
          changeMethod={(type) => props.onChangeState(type, "paymentType")}
        />
      </Grid>

      <Grid.Column mobile={16} tablet={16} computer={16} className="ui-mobile">
        <div className="paymentHeader">
          <Icon size="large" name="long arrow alternate left" />
          COMPARE PAYMENT OPTIONS
          <Icon size="large" name="long arrow alternate right" />
        </div>

        <Carousel
          useKeyboardArrows
          autoPlay={false}
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={false}
          swipeable={true}
          className={"generalCarousel"}
          showStatus={false}
        >
          <Grid className="slide-holder">
            <PaymentMethods
              paymentType={props.state.paymentType}
              type="monthly"
              quots={props.quots}
              changeMethod={(type) => props.onChangeState(type, "paymentType")}
            />
          </Grid>

          <Grid className="slide-holder">
            <PaymentMethods
              paymentType={props.state.paymentType}
              type="weekly"
              quots={props.quots}
              changeMethod={(type) => props.onChangeState(type, "paymentType")}
            />
          </Grid>

          <Grid className="slide-holder">
            <PaymentMethods
              paymentType={props.state.paymentType}
              type="weeklyPlus"
              quots={props.quots}
              changeMethod={(type) => props.onChangeState(type, "paymentType")}
            />
          </Grid>
        </Carousel>
      </Grid.Column>
    </Grid.Column>
  );
};

export default StylePayments;
