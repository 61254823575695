import React, { useState, useEffect } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import Slider from "rc-slider";

import Input from "../../../global/Input";
import Button from "../../../global/Button";

import { calculatePayments } from "../../../helpers/general";

import "./SlidersForm.sass";
import { SimpleRegister } from "../../../session/register/SimpleRegister";

const Handle = Slider.Handle;

const SlidersForm = (props) => {
  const {
    quotationState,
    finalQuots: quot,
    car,
    onChangeState: setState,
    setQuotationState,
    setStep,
    setUserFlow,
    isRandomFlow,
  } = props;

  const [slidersState, setSlidersState] = useState({});

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <Handle value={value} {...restProps}>
        <div className="inner">
          <div className={`wdc-tooltip${dragging ? " active" : ""}`}>
            <span className="wdc-tooltip-inner">{value}</span>
          </div>
        </div>
      </Handle>
    );
  };

  const onChangeState = (value, label) => {
    let finalQuots = { ...quot };
    let max_monthly_payment = quotationState.max_monthly_payment;
    let min_monthly_payment = quotationState.min_monthly_payment;
    let monthly_payment = quotationState.monthly_payment;
    let formatMonthly = quotationState.formatMonthly;

    if (label == "loan_length" && value.target) {
      let { min, max } = value.target;
      let sliderValue = value.target.value;

      value = Math.max(Number(min), Math.min(Number(max), Number(sliderValue)));
    }

    if (label == "loan_length" && car.id) {
      finalQuots = calculatePayments(
        car,
        value,
        quotationState.min_down_payment,
        quotationState.formula,
        quotationState.interestRage[quotationState.credit_score],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      );

      max_monthly_payment = finalQuots.monthly.payment;

      finalQuots = calculatePayments(
        car,
        value,
        quotationState.down_payment,
        quotationState.formula,
        quotationState.interestRage[quotationState.credit_score],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      );

      min_monthly_payment = calculatePayments(
        car,
        value,
        quotationState.max_down_payment,
        quotationState.formula,
        quotationState.interestRage[quotationState.credit_score],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      ).monthly.payment;

      monthly_payment = finalQuots.monthly.payment;
      formatMonthly = parseFloat(monthly_payment).toFixed(2);
    }

    if (label == "credit_score" && car.id) {
      finalQuots = calculatePayments(
        car,
        quotationState.loan_length,
        quotationState.min_down_payment,
        quotationState.formula,
        quotationState.interestRage[value],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      );

      max_monthly_payment = finalQuots.monthly.payment;

      finalQuots = calculatePayments(
        car,
        quotationState.loan_length,
        quotationState.down_payment,
        quotationState.formula,
        quotationState.interestRage[value],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      );

      min_monthly_payment = calculatePayments(
        car,
        quotationState.loan_length,
        quotationState.max_down_payment,
        quotationState.formula,
        quotationState.interestRage[value],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      ).monthly.payment;

      monthly_payment = finalQuots.monthly.payment;
      formatMonthly = parseFloat(monthly_payment).toFixed(2);
    }

    setState(max_monthly_payment, "max_monthly_payment");
    setState(min_monthly_payment, "min_monthly_payment");
    setState(finalQuots, "finalQuots");
    setState(value, String(label));

    setSlidersState((prevState) => ({
      ...prevState,
      max_monthly_payment,
      min_monthly_payment,
      finalQuots,
      formatMonthly,
      [label]: value,
    }));
  };

  const handleOnChangeInput = (event, label) => {
    event.persist();

    setState(event.target.value, label);
    setSlidersState((prevState) => ({
      ...prevState,
      [label]: event.target.value,
    }));
  };

  const setDefaultValues = () => {
    let downMarks = {};

    for (let i = quotationState.min_loan_length; i <= 84; i++) {
      if (i % 12 == 0) {
        downMarks[i] = i + " mo";
      }
    }

    setSlidersState((prevState) => ({
      ...prevState,
      loan_length: quotationState.loan_length,
      min_loan_length: quotationState.min_loan_length,
      max_loan_length: 84,
      downMarks,
    }));
  };

  const handleStateUpdate = () => {
    setSlidersState((prevState) => ({
      ...prevState,
      credit_score: quotationState.credit_score,
      loan_length: quotationState.loan_length,
    }));
  };

  const handleOnClickContinue = () => {
    const myDiv = document.getElementsByClassName("quot-content")[0];
    myDiv.scrollTop = 0;

    setStep(2);
    if (!isRandomFlow) setUserFlow("sliders");
  };

  useEffect(() => {
    setDefaultValues();
  }, []);

  useEffect(() => {
    handleStateUpdate();
  }, [quotationState]);

  return (
    <Grid className={"slidersWrapper"}>
      <Grid.Column mobile={16} tablet={16} className="colum-slider">
        <Segment
          className="segmentGrapper"
          style={{ backgroundColor: "white" }}
        >
          <div className="header-slider">
            <Header as="h3">Loan Length</Header>

            <Input
              min={slidersState.min_loan_length}
              max={slidersState.max_loan_length}
              value={slidersState.loan_length}
              onChange={(event) => handleOnChangeInput(event, "loan_length")}
              onBlur={(event) => onChangeState(event, "loan_length")}
              type="number"
              noMargin
            />
          </div>

          <div className="sliderWrapper" style={{ backgroundColor: "white" }}>
            <Slider
              min={slidersState.min_loan_length}
              max={slidersState.max_loan_length}
              step={1}
              value={slidersState.loan_length}
              onChange={(value) => onChangeState(value, "loan_length")}
              handleStyle={{
                backgroundColor: "white",
              }}
              handle={handle}
              disabled={props.disabled ? true : false}
              tipProps={{
                placement: "bottom",
                overlayClassName: "foo",
                prefixCls: "rc-slider-tooltip",
              }}
              marks={slidersState.downMarks}
            />
          </div>
        </Segment>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} className="colum-slider">
        <Segment className="segmentGrapper">
          <div className="header-slider">
            <Header as="h3">Credit Score Range</Header>

            <span className="only-text">
              {quotationState.credit_range.ranges[quotationState.credit_score]}
            </span>
          </div>

          <div className="sliderWrapper sliderScore">
            <Slider
              min={0}
              max={4}
              step={quotationState.step_credit_range}
              value={quotationState.credit_score}
              onChange={(value) => onChangeState(value, "credit_score")}
              disabled={props.disabled ? true : false}
              tipProps={{
                placement: "bottom",
                overlayClassName: "foo",
                prefixCls: "rc-slider-tooltip",
              }}
              marks={quotationState.credit_range.ranges}
            />
          </div>
        </Segment>
      </Grid.Column>

      <div className={"confirmButtonWrapper"}>
        <SimpleRegister
          CreditSolutionRequest={handleOnClickContinue}
          trigger={
            <Button>
              Continue: Add Trade, Protection & Personalized Options
            </Button>
          }
        />
      </div>
    </Grid>
  );
};

export default SlidersForm;
