import React from "react";
import cn from "classnames";
import { useSwiper } from "swiper/react";

import Button from "../../../../../global/Button";

import { ReactComponent as ArrowIcon } from "../../../../../../images/arrowIcon.svg";

import "./SwiperButton.sass";

const SwiperButton = ({ action }) => {
  const swiper = useSwiper();

  return (
    <Button
      onClick={() => {
        action === "next" ? swiper.slideNext() : swiper.slidePrev();
      }}
      className={cn(
        "swiperButton",
        action === "next" ? "nextButton" : "prevButton"
      )}
      icon
    >
      <ArrowIcon className={cn("arrow", action === "prev" && "rotated")} />
    </Button>
  );
};

export default SwiperButton;
