import React, { useState, useEffect } from "react";
import stringToColor from "string-to-color";

import "./styles.sass";

const Avatar = ({ firstName, lastName, size, onClick }) => {
  const [color, setColor] = useState("#fff");

  useEffect(() => {
    setColor(stringToColor(firstName[0] + lastName[0]));
  }, []);

  return (
    <div
      style={{
        backgroundColor: color,
        height: size + "px",
        width: size + "px",
      }}
      className={"avatarWrapper"}
      onClick={onClick}
    >
      <span>{String(firstName[0] + lastName[0]).toUpperCase()}</span>
    </div>
  );
};

export default Avatar;
