import React from "react";
import { Button } from "semantic-ui-react";

export const BackMenu = (props) => {
  return (
    <div
      className={
        props.menuOpened
          ? "overlay-menu show-overlay ui-mobile"
          : "overlay-menu  ui-mobile"
      }
    >
      {/* <Button
        circular
        icon="arrow alternate circle left outline"
        className="ui-mobile arrow-close"
        onClick={props.openMenu}
      /> */}
    </div>
  );
};
