import moment from "moment";
import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";

import DateInput from "../../../global/DateInput";
import Dropdown from "../../../global/DropDown";
import Button from "../../../global/Button";

import "../PaymentPlans.sass";

const options = [
  { value: "today", text: "Today" },
  { value: "month", text: "This month" },
  { value: "last_month", text: "Last month" },
  { value: "range", text: "Date range" },
];

const statuses = [
  { value: "processed", text: "Processed" },
  { value: "pending", text: "Pending" },
  { value: "failed", text: "Failed" },
  { value: "cancelled", text: "Cancelled" },
];

const types = [
  { value: "balance", text: "From you account to your balance" },
  { value: "lender", text: "From your balance to lender" },
  { value: "master", text: "From your balance to Dwolla(fee trasaction)" },
];

export const PaymentsFilter = ({ filter }) => {
  const [form, setForm] = useState({ option: "", status: "", type: "" });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
  };

  return (
    <Form className="search-form filterForm">
      <Form.Group>
        <Form.Field width={6}>
          <label>Filter Date by:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "option")}
            placeholder={"Filter by"}
            options={options}
            value={form.option}
            clearable
            selection
          />
        </Form.Field>

        {form.option === "range" && (
          <Form.Field width={10}>
            <label>Date range:</label>
            <Grid style={{ display: "contents" }} className={"dateRange"}>
              <Grid.Column width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "startDate");
                  }}
                  value={form.startDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  timeFormat="AMPM"
                />
              </Grid.Column>
              <Grid.Column width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "endDate");
                  }}
                  value={form.endDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  maxDate={moment()}
                  timeFormat="AMPM"
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Field width={6}>
          <label>Filter Status by:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "status")}
            placeholder={"Filter Status by"}
            options={statuses}
            value={form.status}
            selection
            clearable
          />
        </Form.Field>

        <Form.Field width={7}>
          <label>Filter Type by:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "type")}
            placeholder={"Filter Type by"}
            options={types}
            value={form.type}
            selection
            clearable
          />
        </Form.Field>

        <Form.Field width={3} style={{ display: "flex", alignItems: "end" }}>
          <Button
            buttonStyle={"skyblue"}
            onClick={() => filter(form)}
            style={{ width: "100%" }}
            disabled={
              form.option === "range" &&
              (!moment(form.startDate, "MM-DD-YYYY", true).isValid() ||
                !moment(form.endDate, "MM-DD-YYYY", true).isValid())
            }
          >
            Filter
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
