export const adminMenu = [
  {
    name: "dashboard",
    icon: "dashboard",
    label: "Dashboard",
  },
  {
    name: "inventory",
    icon: "inventory",
    label: "Inventory",
  },
  {
    name: "quotes",
    icon: "dollar sign",
    label: "Deals",
  },
  {
    name: "buyers",
    icon: "dollar sign",
    label: "Buyers",
  },
  {
    name: "retailers",
    icon: "list ol",
    label: "Retailers",
  },
  {
    name: "account",
    icon: "account",
    label: "Accounts",
  },
  {
    name: "videos",
    icon: "videos",
    label: "Videos",
  },
  {
    name: "selector",
    icon: "selector",
    label: "Credit Profile Selector",
  },
];

export const retailerMenu = [
  {
    name: "dashboard",
    icon: "dashboard",
    label: "Dashboard",
  },
  {
    name: "inventory",
    icon: "inventory",
    label: "Inventory",
  },
  {
    name: "quotes",
    icon: "dollar sign",
    label: "Deals",
  },
  {
    name: "buyers",
    icon: "dollar sign",
    label: "Buyers",
  },
  {
    name: "ranges",
    icon: "dollar sign",
    label: "Ranges",
  },
  {
    name: "creditRequests",
    icon: "dollar sign",
    label: "Credit Requests",
  },
];

export const customerMenu = [
  {
    name: "dashboard",
    icon: "dashboard",
    label: "Dashboard",
  },
  {
    name: "quotes",
    icon: "dollar sign",
    label: "Deals",
  },
  // {
  //     name:   'loan',
  //     icon:   'loan',
  //     label:  'Loan Approval'
  // },
  {
    name: "contact",
    icon: "user circle outline",
    label: "Contact Data",
  },
];
