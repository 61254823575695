import React, { useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import ModalComponent from "../../alertModal/modal";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { deletePoll } from "../../requests";
import Button from "../../../global/Button";

export const PollDelete = (props) => {
  const { polls, poll } = props;
  const [confirmModal, setConfirmModal] = useState({});
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // ------------------------------ delete account
  const confirmDelete = (flag) => {
    setConfirmModal(false);
    if (flag) {
      removePoll();
    }
  };

  const removePoll = () => {
    setIsLoading(true);
    deletePoll(poll.id)
      .then((response) => {
        setIsLoading(false);
        setModal({ status: 200, message: "Poll was deleted" });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 406) {
            setModal({ status: 406, message: error.response.data.message });
          } else if (error.response.status === 404) {
            setModal({ status: 406, message: "Sorry. Element was not found" });
          } else {
            setModal({
              status: 500,
              message:
                "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support.",
            });
          }
        }
      });
  };

  const hideAlertModal = () => {
    if (modal.status === 200) {
      let auxPolls = [...polls];
      let i = 0;
      for (const item of polls) {
        if (item.id === poll.id) {
          auxPolls.splice(i, 1);
          break;
        }
        i++;
      }
      props.updatePolls(auxPolls, "delete");
    }
    setModal({});
  };

  return (
    <div style={{ display: "inline-block" }} className={"modalBox"}>
      <Button
        buttonStyle={"danger"}
        style={{ minWidth: "80px" }}
        onClick={() =>
          setConfirmModal({
            title: "Delete Poll",
            message: "Are you sure you want to delete the poll?",
          })
        }
      >
        Delete
      </Button>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}

      {modal.message && (
        <ModalComponent modal={modal} hideModal={hideAlertModal} />
      )}

      {confirmModal.message && (
        <ConfirmModal modal={confirmModal} afterEvent={confirmDelete} />
      )}
    </div>
  );
};
