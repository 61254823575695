import React from "react";

import { Form, Header } from "semantic-ui-react";

import Input from "../../../global/Input";

const CreditScoreRange = (props) => {
  return (
    <>
      <Header as={"h3"}>Credit Score Range</Header>

      <Form.Group>
        <Form.Field width={3}>
          <label>
            Poor <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>Under {props.state.poor}</span>
          ) : (
            <div>
              <Input
                style={{ width: "100%" }}
                value={props.state.poor}
                placeholder={"Poor"}
                onChange={(e) => props.onEditInput("poor", e.target.value)}
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.poor.length > 0 &&
                  props.state.msnErrors.poor
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={3}>
          <label>
            Fair <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.poor + " - " + props.state.fair}</span>
          ) : (
            <div>
              <Input
                style={{ width: "100%" }}
                value={props.state.fair}
                placeholder={"Fair"}
                onChange={(e) => props.onEditInput("fair", e.target.value)}
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.fair.length > 0 &&
                  props.state.msnErrors.fair
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={3}>
          <label>
            Good <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.fair + " - " + props.state.good}</span>
          ) : (
            <div>
              <Input
                style={{ width: "100%" }}
                value={props.state.good}
                placeholder={"Good"}
                onChange={(e) => props.onEditInput("good", e.target.value)}
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.good.length > 0 &&
                  props.state.msnErrors.good
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={3}>
          <label>
            Very Good <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.good + " - " + props.state.very_good}</span>
          ) : (
            <div>
              <Input
                style={{ width: "100%" }}
                value={props.state.very_good}
                placeholder={"Very Good"}
                onChange={(e) => props.onEditInput("very_good", e.target.value)}
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.very_good.length > 0 &&
                  props.state.msnErrors.very_good
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={4}>
          <label>
            Exceptional <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>
              {props.state.very_good + " - " + props.state.exceptional}
            </span>
          ) : (
            <div>
              <Input
                style={{ width: "100%" }}
                value={props.state.exceptional}
                placeholder={"Exceptional"}
                onChange={(e) =>
                  props.onEditInput("exceptional", e.target.value)
                }
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.exceptional.length > 0 &&
                  props.state.msnErrors.exceptional
                }
              />
            </div>
          )}
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default CreditScoreRange;
