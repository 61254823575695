import React, { useEffect, useState } from "react";
import { Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import ModalComponent from "../alertModal/modal";
import { getMessages, postMessage } from "../requests";
import { Message } from "./Message";
import Button from "../../global/Button";
import TextArea from "../../global/TextArea";

import "./Messages.sass";

export const Messages = ({ id, name, role, clearMessages }) => {
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);

  const sendMsn = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let params = {
      role,
      id,
      message: text,
    };
    postMessage(params)
      .then((response) => {
        setIsLoading(false);
        let auxMessages = [...messages];
        auxMessages.push(response.data);
        setMessages(auxMessages);
        setText("");
        scrollDown();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          setModal({
            status: 500,
            message:
              "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support.",
          });
        }
      });
  };

  // --------------------------------- move scroll to unread messages
  const scrollDown = () => {
    var myElement = document.getElementById("box-msn-" + role);
    var topPos = myElement.offsetHeight * myElement.offsetTop;
    document.getElementById("box-msn-" + role).scrollTop = topPos;
  };

  useEffect(() => {
    setIsLoading(true);
    getMessages({ id, role })
      .then((response) => {
        setIsLoading(false);
        let auxMessages = response.data;
        let readFlag = false;
        for (const msn of auxMessages) {
          msn.readFlag = false;
          if (
            msn.status === 0 &&
            ((role === "retailer" && msn.type === 2) ||
              (role === "customer" && msn.type === 1))
          ) {
            msn.readFlag = true;
            readFlag = true;
            break;
          }
        }
        const unreadMessages = auxMessages.filter(
          (item) =>
            item.status === 0 &&
            ((role === "retailer" && item.type === 2) ||
              (role === "customer" && item.type === 1))
        ).length;
        clearMessages(unreadMessages);
        setMessages(auxMessages);
        if (!readFlag) {
          scrollDown();
        } else {
          var element = document.getElementById("no-read-" + role);
          element.scrollIntoView();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          setModal({
            status: 500,
            message:
              "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support.",
          });
        }
      });
  }, [id]);

  return (
    <div>
      <div className="body" id={"box-msn-" + role}>
        {messages.map((item) => (
          <Message message={item} role={role} />
        ))}
      </div>
      <Form className="action">
        <Form.Group>
          <Form.Field width={16}>
            <TextArea
              onChange={(e) => setText(e.target.value)}
              placeholder={"Type a message..."}
              value={text}
              className={"textarea"}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Form className="action">
        <Form.Group className={"buttons"}>
          <Form.Field width={8}>
            <Button
              buttonStyle={"skyblue"}
              disabled={!text}
              onClick={() => setText("")}
            >
              Clear <Icon name="eraser" />
            </Button>
          </Form.Field>
          <Form.Field width={8}>
            <Button
              buttonStyle={"primary"}
              disabled={!text || text.split(" ").join("") === ""}
              onClick={sendMsn}
            >
              Send <Icon name="paper plane outline" />
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
      {modal.message && (
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      )}
    </div>
  );
};
