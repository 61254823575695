import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import _ from "lodash";

import Swiper from "./Swiper";
import Button from "../../global/Button";

import { ReactComponent as CrossIcon } from "../../../images/crossIcon.svg";

import "./AddProtectionCoverageModal.sass";
import QuestionnaireModal from "./QuestionnaireModal";

const protectionPlans = ["full", "standard", "base"];

const AddProtectionCoverageModal = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const open = () => {
    setIsOpen(true);
  };

  const close = (withoutSelectedPlan = false) => {
    if (!_.isNull(selectedPlan) || withoutSelectedPlan) {
      setIsOpen(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} onClose={close} className={"protectionModal"}>
        <Modal.Content>
          <div className={"modalContent"}>
            <QuestionnaireModal
              isPlanSelected={_.isNull(selectedPlan)}
              onClick={close}
            >
              <Button icon className={"crossIcon"}>
                <CrossIcon />
              </Button>
            </QuestionnaireModal>

            <Swiper
              slides={protectionPlans}
              selectedPlan={selectedPlan}
              onSelect={setSelectedPlan}
            />
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default AddProtectionCoverageModal;
