import React from "react";

import { Grid, Icon, Header, Form, Popup } from "semantic-ui-react";
import Button from "../../../global/Button";

import { TradeModal } from "../../car/TradeModal";

const AdditionalInfo = (props) => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Grid.Column mobile={16} tablet={10} computer={10} className={"actions"}>
        <div>
          {props.state.showPdf && !props.state.hideElementsPdf && (
            <a className="iconPdf" onClick={props.pdf}>
              Download PDF <Icon name="file pdf outline" />
            </a>
          )}

          <h3 className="quote-status">
            Deal status:
            <span>
              {props.state.quot.status == 0
                ? " Pending"
                : props.state.quot.status == 1
                ? " Created"
                : props.state.quot.status == 2
                ? " Accepted"
                : props.state.quot.status == 3
                ? " Cancelled"
                : props.state.quot.status == 4
                ? " Expired"
                : null}
            </span>
          </h3>
        </div>

        <div>
          {!props.state.hideElementsPdf ? (
            <div>
              {!props.state.editDisabled ? (
                props.state.quot.retailer.credit_range.insurance_protection ? (
                  <div className="insurance-protection">
                    <Form.Checkbox
                      className="agree-check"
                      checked={props.state.insurance_protection}
                      label={"Insurance and protection"}
                      onChange={(e, value) =>
                        props.onChangeState(
                          value.checked,
                          "insurance_protection"
                        )
                      }
                    />

                    <Popup
                      content={
                        props.state.retailer_credit_range
                          .text_insurance_protection
                      }
                      trigger={<Icon link name="info circle" />}
                    />
                  </div>
                ) : null
              ) : props.state.quot.retailer.credit_range.insurance_protection ||
                props.state.quot.insurance_protection ? (
                <Header className="monthlyPayment" as="h4">
                  {props.state.insurance_protection ? (
                    <Icon name="check" />
                  ) : (
                    <Icon name="close" />
                  )}
                  Insurance and protection
                </Header>
              ) : null}

              <TradeModal
                trade_in={props.state.trade_in}
                money_owed={props.state.money_owed}
                disabledTrade={props.state.editDisabled}
                tradeChange={props.onChangeState}
                detail={true}
              />

              {props.state.quot.status == 0 ||
                (props.state.quot.status == 1 && (
                  <div style={{ display: "inline-block" }}>
                    {props.state.editDisabled ? (
                      props.state.role == "Customer" ? (
                        <Button
                          className="btn-continue"
                          buttonStyle={"secondary"}
                          onClick={(e) => props.checkFormula()}
                        >
                          Edit deal
                        </Button>
                      ) : null
                    ) : props.state.role == "Customer" ? (
                      <div
                        style={{
                          display: "block",
                          margin: "0 0 10px 0",
                        }}
                      >
                        <Button
                          buttonStyle={"danger"}
                          className="btn-cancel"
                          onClick={(e) => props.cancelEdit()}
                        >
                          Cancel edit
                        </Button>

                        <Button
                          className="btn-continue"
                          onClick={(e) => props.editQuote()}
                        >
                          Save deal
                        </Button>
                      </div>
                    ) : null}

                    {props.state.role == "Customer" && (
                      <Button
                        buttonStyle={"danger"}
                        className="btn-cancel-quote"
                        onClick={(e) => props.changeQuotStatus(3)}
                      >
                        Cancel deal
                      </Button>
                    )}

                    {props.state.role == "Retailer" &&
                      props.state.quot.status == 1 && (
                        <Button
                          buttonStyle={"danger"}
                          className="btn-cancel-quote"
                          onClick={(e) => props.changeQuotStatus(3)}
                        >
                          Cancel deal
                        </Button>
                      )}

                    {props.state.role == "Retailer" &&
                      props.state.quot.status == 1 && (
                        <Button
                          className="btn-accept-quote"
                          onClick={(e) => props.changeQuotStatus(2)}
                        >
                          Accept deal
                        </Button>
                      )}
                  </div>
                ))}

              <div className={"buyerInformationButtonWrapper"}>
                {props.state.role == "Admin" ||
                  (props.state.role == "Retailer" && (
                    <Button
                      className="btn-continue"
                      onClick={props.onOpenBuyerModal}
                      buttonStyle={"skyblue"}
                    >
                      Buyer Information
                    </Button>
                  ))}
              </div>
            </div>
          ) : (
            <Header className="monthlyPayment" as="h4">
              {props.state.insurance_protection ? (
                <Icon name="check" />
              ) : (
                <Icon name="close" />
              )}
              Insurance and protection
            </Header>
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default AdditionalInfo;
