import React, { useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Pagination,
} from "semantic-ui-react";
import { calculatePayments } from "../../../helpers/general";
import Car from "../../../utilities/car/Car";
import Footer from "../footer/Footer";
import Video from "../video/Video";

import "./carsearch.sass";
import CarsHeader from "./CarsHeader";

const CarSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [carType, setCarType] = useState("monthly");
  const [activePage, setActivePage] = useState(props.activePage);

  const handlePaginationChange = (e, { activePage }) => {
    props.getCars(carType, activePage);
  };

  // -------------------------------------- show car efect when change of type o payment ------------------------------------
  const efectCars = (carType) => {
    setCarType(carType);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setActivePage(props.activePage);
  }, [props.carsPaymentPayment]);

  return (
    <div className="result-cars">
      <div className="items-grapper">
        <Video flag={false} video={"search"} videos={props.videos} />
        <div className="ui-computer">
          <Header as="h2" className="title-search ui-computer">
            Search Results
          </Header>
        </div>
        <div className="tabs-payments">
          <CarsHeader
            carsPayment={props.carsPayment}
            carType={carType}
            efectCars={(type) => efectCars(type)}
            type={"monthly"}
          />
          <CarsHeader
            carsPayment={props.carsPayment}
            carType={carType}
            efectCars={(type) => efectCars(type)}
            type={"weekly"}
          />
          <CarsHeader
            carsPayment={props.carsPayment}
            carType={carType}
            efectCars={(type) => efectCars(type)}
            type={"weeklyPlus"}
          />
        </div>
      </div>
      <div
        className="ui-mobile"
        style={{ width: "100%", padding: "10px 0px", textAlign: "center" }}
      >
        <Pagination
          activePage={activePage[carType]}
          totalPages={props.carsPayment[carType].last_page}
          onPageChange={handlePaginationChange}
        />
      </div>
      <div className="cars-grapper">
        {props.carsPayment[carType].data.map((car, ix) => (
          <div className="cars">
            <Grid>
              <Grid.Column mobile={16} tablet={14} computer={14}>
                <Car
                  type={"cars"}
                  car={car}
                  interest={props.interestRage[props.credit_score]}
                  formula={props.formula}
                  quots={calculatePayments(
                    car,
                    props.loan_length,
                    props.down_payment,
                    props.formula,
                    props.interestRage[props.credit_score],
                    props.insurance_protection,
                    props.trade_in,
                    props.money_owed
                  )}
                />
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={2}
                computer={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="ui-computer">
                  <Button
                    className="btn-arrow"
                    onClick={(e) => props.carContinue(car)}
                  >
                    <Icon name="angle right" />
                  </Button>
                </div>
                <div className="ui-mobile" style={{ width: "100%" }}>
                  <Button
                    className="btn-arrow-m"
                    onClick={(e) => props.carContinue(car)}
                  >
                    Details
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        ))}
        {props.carsPayment[carType] &&
        props.carsPayment[carType].data.length == 0 ? (
          <h3 style={{ textAlign: "center" }}>
            Sorry, We couldn't find any results
          </h3>
        ) : null}
      </div>
      <div
        className="ui-computer"
        style={{ width: "100%", padding: "10px 0px", textAlign: "center" }}
      >
        <Pagination
          activePage={activePage[carType]}
          totalPages={props.carsPayment[carType].last_page}
          onPageChange={handlePaginationChange}
        />
      </div>
      <Footer finalQuots={props.finalQuots} />
      {loading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};

export default CarSearch;
