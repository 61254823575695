import React, { useEffect, useState } from "react";
import { Form, Grid } from "semantic-ui-react";

import Input from "../../../global/Input";
import TextArea from "../../../global/TextArea";
import Dropdown from "../../../global/DropDown";

import "./EditCarButton.sass";

const saleSatatusOptions = [
  { value: "used", text: "Pre-owned" },
  { value: "new", text: "New" },
];
const certifiedOptions = [
  { value: "yes", text: "Yes" },
  { value: "no", text: "No" },
];

export const BasicData = (props) => {
  const [car, setCar] = useState(props.car);
  const [errors, setErrors] = useState({});

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...car };
    auxForm[label] = value;
    setCar({ ...auxForm });
    props.updateCar(auxForm);
    validation(auxForm);
  };

  const validation = (auxForm) => {
    let auxErrors = { ...errors };
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors);
      }
    }
    setErrors({ ...auxErrors });
    props.setDisabled(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          props.setDisabled(true);
          break;
        }
      }
    }
  };

  const validateForm = (value, label, errors) => {
    switch (label) {
      case "msrp":
      case "cost":
      case "price":
      case "adjusted_price":
        errors[label] =
          !value || isNaN(value) ? "The field must be a number" : null;
        break;
      default:
        break;
    }
    return errors;
  };

  useEffect(() => {
    validation(props.car);
  }, []);

  return (
    <Grid>
      <Grid.Column width={16} className={"formWrapper"}>
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <label>MAKE:</label>

              <Input value={car.make} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>Model:</label>

              <Input value={car.model} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>TRIM LEVEL:</label>

              <Input value={car.trim} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>Year:</label>

              <Input value={car.year} disabled />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>MSRP Price($):</label>

              <Input
                onChange={(e) => onEditInput(e.target.value, "msrp")}
                placeholder={"MSRP Price"}
                value={car.msrp}
                error={car.msrp || errors.msrp ? errors.msrp : null}
              />
            </Form.Field>

            <Form.Field width={8}>
              <label>
                Price($):<span className="required-inut"> *</span>
              </label>

              <Input
                onChange={(e) => onEditInput(e.target.value, "price")}
                placeholder={"Price"}
                value={car.price}
                error={car.price || errors.price ? errors.price : null}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>
                Cost($):<span className="required-inut"> *</span>
              </label>

              <Input
                onChange={(e) => onEditInput(e.target.value, "cost")}
                placeholder={"Cost"}
                value={car.cost}
                error={car.cost || errors.cost ? errors.cost : null}
              />
            </Form.Field>

            <Form.Field width={8}>
              <label>
                Adjusted Price($):<span className="required-inut"> *</span>
              </label>

              <Input
                onChange={(e) => onEditInput(e.target.value, "adjusted_price")}
                placeholder={"Adjusted Pric"}
                value={car.adjusted_price}
                error={
                  car.adjusted_price || errors.adjusted_price
                    ? errors.adjusted_price
                    : null
                }
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>VIN:</label>

              <Input value={car.vin} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>STOCK #:</label>

              <Input value={car.stock_number} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>Mfg Code:</label>

              <Input value={car.mfgcode} disabled />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>Transmission:</label>

              <TextArea
                className={"formTextarea"}
                value={car.transmission}
                disabled
              />
            </Form.Field>

            <Form.Field width={4}>
              <label>Engine:</label>

              <TextArea
                className={"formTextarea"}
                value={car.engine}
                disabled
              />
            </Form.Field>

            <Form.Field width={4}>
              <label>MILES:</label>

              <Input value={car.miles} disabled />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>Body Style:</label>

              <TextArea
                className={"formTextarea"}
                value={car.bodystyle}
                rows={2}
                disabled
              />
            </Form.Field>

            <Form.Field width={4}>
              <label>Doors:</label>

              <Input value={car.doors} disabled />
            </Form.Field>

            <Form.Field width={4}>
              <label>Cylinders:</label>

              <Input value={car.cylinders} disabled />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={5}>
              <label>Interior Color:</label>

              <Input value={car.interior_color} disabled />
            </Form.Field>

            <Form.Field width={5}>
              <label>Interior Color Code:</label>

              <Input value={car.interior_color_code} disabled />
            </Form.Field>

            <Form.Field width={5}>
              <label>Exterior Color:</label>

              <Input value={car.exterior_color} disabled />
            </Form.Field>

            <Form.Field width={5}>
              <label>Exterior Color Code:</label>

              <Input value={car.exterior_color_code} disabled />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>Condition:</label>

              <Dropdown
                value={car.condition}
                options={saleSatatusOptions}
                disabled
              />
            </Form.Field>

            <Form.Field width={8}>
              <label>Certified:</label>

              <Dropdown
                value={car.certified}
                options={certifiedOptions}
                disabled
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Grid.Column>
    </Grid>
  );
};
