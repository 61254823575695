import moment from "moment";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";

import DateInput from "../../global/DateInput";
import Input from "../../global/Input";
import Dropdown from "../../global/DropDown";
import Button from "../../global/Button";

import "./Accounts.sass";

const options = [
  { value: "today", text: "Today" },
  { value: "month", text: "This month" },
  { value: "last_month", text: "Last month" },
  { value: "range", text: "Date range" },
];

export const AccountsFilter = ({ filter }) => {
  const [form, setForm] = useState({ option: "", status: "", type: "" });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
  };

  return (
    <Form style={{ marginTop: "1rem" }}>
      <Form.Group>
        <Form.Field width={6}>
          <label>Filter Date by:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "option")}
            placeholder={"Filter by"}
            options={options}
            value={form.option}
            clearable
            selection
          />
        </Form.Field>

        {form.option === "range" && (
          <Form.Field width={10}>
            <label>Date range:</label>
            <div className={"datesWrapper"}>
              <div width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "startDate");
                  }}
                  value={form.startDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  timeFormat="AMPM"
                />
              </div>

              <div width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "endDate");
                  }}
                  value={form.endDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  maxDate={moment()}
                  timeFormat="AMPM"
                />
              </div>
            </div>
          </Form.Field>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Field width={6}>
          <label>Filter by email:</label>

          <Input
            onChange={(e) => onEditInput(e.target.value, "keyword")}
            placeholder={"Filter by email"}
            value={form.keyword}
          />
        </Form.Field>

        <Form.Field
          width={3}
          style={{ display: "flex", alignItems: "end", marginTop: "1rem" }}
        >
          <Button
            buttonStyle={'skyblue'}
            onClick={() => filter(form)}
            style={{ width: "100%" }}
            disabled={
              form.option === "range" &&
              (!moment(form.startDate, "MM-DD-YYYY", true).isValid() ||
                !moment(form.endDate, "MM-DD-YYYY", true).isValid())
            }
          >
            Filter
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
