import React from "react";
import cn from "classnames";

import { ReactComponent as CheckedIcon } from "../../../../../../images/checkIcon.svg";

import { ReactComponent as CrossIcon } from "../../../../../../images/crossIcon.svg";

import "./ListItem.sass";

const ListItem = ({ label, isSupported }) => {
  return (
    <li className={"listItem"}>
      <span>{label}</span>

      <div className={"iconWrapper"}>
        {isSupported ? (
          <CheckedIcon />
        ) : (
          <CrossIcon className={"dangerColor"} />
        )}
      </div>
    </li>
  );
};

export default ListItem;
