import React, { useEffect, useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import { states } from "../../helpers/usaStates";
import { GoogleAutocomplete } from "../googleAutocomplete/GoogleAutocomplete";

import Input from "../../global/Input";
import Dropdown from "../../global/DropDown";

import "./Address.sass";

const usaState = states().map((item) => {
  return {
    text: item.name,
    value: item.isoCode,
  };
});

const Address = (props) => {
  const [form, setForm] = useState(
    props.address
      ? props.address
      : { address: "", city: "AL", zip_code: "", state: "" }
  );

  // ----------------------- get form values
  const onChangeState = (value, flag) => {
    let auxForm = { ...form };

    if (!flag) {
      auxForm.address = value.address;
      auxForm.city = value.locationCity;
      auxForm.zip_code = value.postalCode;

      let state = "";

      if (value.locationState) {
        for (const item of usaState) {
          if (value.locationState === item.text) {
            state = item.value;

            break;
          }
        }
      }

      auxForm.state = state;
      props.onChangeState("fullAddress", auxForm);
    } else {
      auxForm["address"] = value;
      props.onChangeState("address", auxForm.address);
    }

    setForm(auxForm);
  };

  // ------------------------------------ change input values --------------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm(auxForm);
    props.onChangeState(label, value);
  };

  useEffect(() => {
    setForm(
      props.address
        ? props.address
        : { address: "", city: "AL", zip_code: "", state: "" }
    );
  }, [props]);

  return (
    <Grid>
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={16}
        style={props.noPadding ? { padding: "0" } : {}}
      >
        <Form.Group>
          <Form.Field width={16} className={"addressField"}>
            <GoogleAutocomplete
              value={form.address}
              onChangeSelect={(value, flag) => onChangeState(value, flag)}
              disabled={props.flagEdit}
              validateTyping
              error={
                form.address &&
                props.msnErrors.address &&
                props.msnErrors.address
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Group className={"triple"}>
          <Form.Field width={6} className={"addressField dropdownField"}>
            <label>
              State <span className="required-inut">*</span>
            </label>

            <Dropdown
              id={"state"}
              placeholder="State"
              search
              options={usaState}
              value={form.state}
              onChange={(e, { value }) => {
                onEditInput("state", value);
              }}
              error={
                form.state || props.msnErrors.state
                  ? props.msnErrors.state
                  : null
              }
              disabled={props.flagEdit}
              selection
            />
          </Form.Field>

          <Form.Field width={6}>
            <label>
              City <span className="required-inut">*</span>
            </label>

            <Input
              id={"city"}
              placeholder="City"
              fluid
              value={form.city}
              onChange={(e) => onEditInput("city", e.target.value)}
              error={
                form.city || props.msnErrors.city ? props.msnErrors.city : null
              }
              disabled={props.flagEdit}
            />
          </Form.Field>

          <Form.Field width={4}>
            <label>
              Zip Code <span className="required-inut">*</span>
            </label>

            <Input
              value={form.zip_code}
              placeholder={"Zip Code"}
              onChange={(e) => onEditInput("zip_code", e.target.value)}
              fluid
              error={
                form.zip_code || props.msnErrors.zip_code
                  ? props.msnErrors.zip_code
                  : null
              }
              maxLength="50"
              disabled={props.flagEdit}
            />
          </Form.Field>
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};

export default Address;
