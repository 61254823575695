import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid, Dimmer, Loader, Message, Icon } from "semantic-ui-react";
import {
  getQuot,
  editQuot,
  editQuotStatusRetailer,
  editQuotStatusCustomer,
} from "../requests";
import {
  calculateMaxDownPayment,
  calculatePayments,
  getCarPrice,
  interestRageBuild,
} from "../../helpers/general";

import ConfirmModal from "./ConfirmModal";
import AdditionalInfo from "./AdditionalInfo";
import PaymentInfo from "./PaymentInfo";

// IMPORT COMPONENTS
import Car from "../../utilities/car/Car";
import BuyerInformation from "../buyerInformation/buyerInformation";
import ModalError from "../alertModal/modal";

// IMPORT CSS STYLES
import "./quotationDetails.sass";

class QuotationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: {},
      modal: {},
      changedValues: "",
      // ------------------------------ get kind of user ------------------
      role: "Customer",
      // -----------------------------------------------------------------
      quot: {
        customer: {},
        retailer: {},
      },
      // ----------------------------- quote results ----------------------
      finalQuots: {
        monthly: {},
        weekly: {},
        weeklyPlus: {},
      },
      paymentType: "",
      plan: false,
      formula: {},
      // --------------------------- the slider gets the info car ------------
      step: 0,
      // ------------------------- edit sliders --------------------
      retailer_credit_range: {},
      trade_in: 0,
      money_owed: 0,
      monthly_payment: 1000,
      min_monthly_payment: 0,
      max_monthly_payment: 1000,
      aux_min_monthly: 0,
      aux_max_monthly: 1000,
      steps_monthly_payment: {},
      loan_length: 84,
      down_payment: 2000,
      min_down_payment: 0,
      max_down_payment: 2000,
      min_loan_length: 1,
      max_loan_length: 84,
      credit_score: 4,
      credit_range: {
        min: 0,
        max: 1000,
        ranges: {},
        select: [],
      },
      interestRage: {},
      car: {},
      insurance_protection: false,
      editDisabled: true,
      // -------------------------- show buyer information -------------------
      buyerModal: false,
      // -------------------------- show confirm modal ----------------------
      confirmModal: false,
      // -------------------------- validate error formulas change ----------
      formulaChanged: {},
      // -------------------------- variables for pdf creator ---------------
      hideElementsPdf: false,
      showPdf: true,
    };
  }

  componentDidMount() {
    let id = this.props.quotId ? this.props.quotId : this.props.match.params.id;
    this.setState({ loading: true });
    getQuot(id)
      .then((response) => {
        this.setState({ role: response.data.role });
        let result = {
          status: response.status,
          data: response.data.quot,
        };
        this.quoteState(result);
        if (this.props.getName) {
          this.props.getName(
            `${response.data.quot.car.make} ${response.data.quot.car.model}`
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          if (error.response.status == 404) {
            this.setState({ error: { message: "Deal was not found" } });
          }
        }
      });
  }

  quoteState = (response) => {
    if (
      response.data.formula == null ||
      response.data.retailer.credit_range == null
    ) {
      if (response.status == 200) {
        this.setState({
          loading: false,
          error: { message: "Deal was not found" },
        });
        return;
      } else if (response.status == 202) {
        this.setState({
          loading: false,
          modal: {
            status: 400,
            message: "Sorry. An error occurred, please try again later",
          },
        });
        return;
      }
    }
    // ------------------------------- update credit range --------------------------------
    let insurance_protection = response.data.insurance_protection;
    let trade_in = response.data.trade_in;
    let money_owed = response.data.money_owed;
    let monthly_payment = response.data.monthly_payment;
    let loan_length = response.data.loan_length;
    let down_payment = response.data.down_payment;
    let credit_score = response.data.credit_score_range;
    let credit_range = this.state.credit_range;
    let retailer_credit_range = response.data.retailer.credit_range;
    let max_monthly_payment = this.state.max_monthly_payment;
    let min_monthly_payment = this.state.min_monthly_payment;
    let min_down_payment = this.state.min_down_payment;
    let max_down_payment = this.state.max_down_payment;
    let min_loan_length = this.state.min_loan_length;
    let max_loan_length = this.state.max_loan_length;
    let interestRage = this.state.interestRage;
    let formula = response.data.formula;
    let paymentType = response.data.type;
    let cox =
      response.data.cox != null ? Object.assign({}, response.data.cox) : null;
    let car_price = response.data.car.price;
    let aux_trade_in = trade_in,
      aux_money_owed = +money_owed;
    if (cox != null) {
      car_price = cox.amountFinanced;
      formula.dealer_fees = cox.dealer_fees;
      formula.tax_rate = cox.tax_rate;
      if (
        cox?.incentives?.totalDealerCash > 0 ||
        cox?.incentives?.totalRebate > 0
      ) {
        formula.incentives = Object.assign({}, cox.incentives);
      }
    } else {
      car_price = getCarPrice(response.data.car, formula, insurance_protection);
    }
    let resTrade = +trade_in - aux_money_owed;
    if (resTrade < 0) {
      car_price += -resTrade;
    }
    max_down_payment = car_price;
    // --------------------------------- add ranges -----------------------------------------
    if (retailer_credit_range != null) {
      credit_range.min = retailer_credit_range.poor;
      credit_range.max = retailer_credit_range.exceptional;
      credit_range.ranges = {
        [0]: (
          <span>
            Under {retailer_credit_range.poor}
            <p>Poor</p>
          </span>
        ),
        [1]: (
          <span>
            {retailer_credit_range.poor}-{retailer_credit_range.fair}
            <p>Fair</p>
          </span>
        ),
        [2]: (
          <span>
            {retailer_credit_range.fair + 1}-{retailer_credit_range.good}
            <p>Good</p>
          </span>
        ),
        [3]: (
          <span>
            {retailer_credit_range.good + 1}-{retailer_credit_range.very_good}
            <p>Very Good</p>
          </span>
        ),
        [4]: (
          <span>
            {retailer_credit_range.very_good + 1}-
            {retailer_credit_range.exceptional}
            <p>Exceptional</p>
          </span>
        ),
      };
      credit_range.select = [
        {
          value: retailer_credit_range.poor,
          text: "Under " + retailer_credit_range.poor + " Poor",
        },
        {
          value: retailer_credit_range.fair,
          text:
            retailer_credit_range.poor +
            "-" +
            retailer_credit_range.fair +
            " Fair",
        },
        {
          value: retailer_credit_range.good,
          text:
            retailer_credit_range.fair +
            1 +
            "-" +
            retailer_credit_range.very_good +
            " Good",
        },
        {
          value: retailer_credit_range.very_good,
          text:
            retailer_credit_range.very_good +
            1 +
            "-" +
            retailer_credit_range.very_good +
            " Very Good",
        },
        {
          value: retailer_credit_range.exceptional,
          text:
            retailer_credit_range.very_good +
            1 +
            "-" +
            retailer_credit_range.exceptional +
            " Exceptional",
        },
      ];
      // -------------------------- set min and max values in sliders -----------------------------
      if (
        retailer_credit_range.min_down_payment != null &&
        retailer_credit_range.min_down_payment >= 0
      ) {
        min_down_payment =
          (retailer_credit_range.min_down_payment / 100) * car_price;
        if (min_down_payment > response.data.down_payment) {
          min_down_payment = response.data.down_payment;
        }
      } else {
        min_down_payment = 0;
      }
      if (
        retailer_credit_range.max_down_payment != null &&
        retailer_credit_range.max_down_payment > 0
      ) {
        max_down_payment =
          (retailer_credit_range.max_down_payment / 100) * car_price;
        if (max_down_payment < response.data.down_payment) {
          max_down_payment = response.data.down_payment;
        }
      } else {
        if (response.data.car.id) {
          max_down_payment = car_price;
        } else {
          max_down_payment = 20000;
        }
      }
      if (
        retailer_credit_range.min_loan_length != null &&
        retailer_credit_range.min_loan_length >= 0
      ) {
        min_loan_length = retailer_credit_range.min_loan_length;
        if (
          response.data.car.id &&
          min_loan_length > response.data.loan_length
        ) {
          min_loan_length = response.data.loan_length;
        }
      }
      if (
        retailer_credit_range.max_loan_length != null &&
        retailer_credit_range.max_loan_length > 0
      ) {
        max_loan_length = retailer_credit_range.max_loan_length;
        if (response.data.car.condition === "new") {
          max_loan_length += 12;
          if (
            response.data.car.id &&
            max_loan_length < response.data.loan_length
          ) {
            max_loan_length = response.data.loan_length;
          }
        }
        if (response.data.car.condition === "used") {
          max_loan_length = 72;
          if (min_loan_length > max_loan_length)
            min_loan_length = max_loan_length;
        }
      }
    }
    // ------------------------------------ match credit score with annual interest rate -------------------
    interestRage = cox != null ? cox.interestRage : interestRageBuild(formula);
    trade_in = response.data.trade_in;
    // ------------------------------ calculate max in min --------------
    down_payment = min_down_payment;
    if (+trade_in >= +money_owed) {
      aux_money_owed = 0;
      aux_trade_in = 0;
    }
    let auxQuotes = calculatePayments(
      response.data.car,
      response.data.loan_length,
      down_payment,
      response.data.formula,
      interestRage[response.data.credit_score_range],
      response.data.insurance_protection,
      aux_trade_in,
      aux_money_owed
    );
    if (retailer_credit_range != null) {
      max_down_payment = calculateMaxDownPayment(
        auxQuotes.monthly.final_price,
        retailer_credit_range
      );
    }
    // --------------- set max down payment form Cox
    if (cox != null) {
      max_down_payment = calculateMaxDownPayment(
        cox.amountFinanced,
        retailer_credit_range
      );
    }
    let finalQuots = calculatePayments(
      response.data.car,
      response.data.loan_length,
      response.data.down_payment,
      response.data.formula,
      interestRage[response.data.credit_score_range],
      response.data.insurance_protection,
      trade_in,
      money_owed
    );
    monthly_payment = finalQuots.monthly.payment;
    max_monthly_payment = auxQuotes.monthly.payment;
    let aux_max_monthly = max_monthly_payment;
    auxQuotes = calculatePayments(
      response.data.car,
      response.data.loan_length,
      max_down_payment,
      response.data.formula,
      interestRage[response.data.credit_score_range],
      response.data.insurance_protection,
      this.state.trade_in,
      money_owed
    );
    min_monthly_payment = auxQuotes.monthly.payment;
    let aux_min_monthly = min_monthly_payment;
    down_payment = response.data.down_payment;
    if (this.props.insideDashboard) {
      this.props.updateCreatedQuots(
        response.data.status,
        response.data.id,
        false
      );
    }
    this.setState({
      quot: response.data,
      interestRage,
      finalQuots,
      monthly_payment,
      max_monthly_payment,
      min_monthly_payment,
      loan_length,
      down_payment,
      min_down_payment,
      max_down_payment,
      min_loan_length,
      max_loan_length,
      credit_score,
      credit_range,
      car: response.data.car,
      insurance_protection,
      formula: response.data.formula,
      paymentType,
      trade_in,
      money_owed,
      editDisabled: true,
      showPdf: true,
      retailer_credit_range,
      aux_max_monthly,
      aux_min_monthly,
      cox,
      loading: false,
      modal:
        response.status == 202
          ? { status: 200, message: "Your deal has been update" }
          : {},
    });
  };

  // ---------------------------- EDIT QUOTE ---------------------------------
  getRangeCashDown = (value, insurance_protection) => {
    let result = value;
    let carPrice = getCarPrice(
      this.state.quot.car,
      this.state.quot.retailer.formula,
      insurance_protection
    );
    if (this.state.quot.cox != null) {
      carPrice = this.state.quot.cox.amountFinanced;
    }
    if (this.state.quot.cox == null) {
      let newTrade = +this.state.quot.trade_in - +this.state.quot.money_owed;
      if (newTrade < 0) {
        carPrice += -newTrade;
      }
    }
    result = (value / 100) * carPrice;
    return result;
  };

  checkFormula = () => {
    let text = "",
      flag = false,
      quot = this.state.quot;
    if (this.state.formula.id != this.state.quot.retailer.formula.id) {
      text += "<p>The interest percentages have changed.</p>";
      flag = true;
    }
    if (
      quot.retailer.credit_range.min_down_payment != null &&
      quot.retailer.credit_range.min_down_payment >= 0 &&
      quot.retailer.credit_range.max_down_payment != null &&
      quot.retailer.credit_range.max_down_payment >= 0
    ) {
      if (
        this.state.quot.down_payment >
          this.getRangeCashDown(
            this.state.quot.retailer.credit_range.max_down_payment,
            this.state.insurance_protection
          ) ||
        this.state.quot.down_payment <
          this.getRangeCashDown(
            this.state.quot.retailer.credit_range.min_down_payment,
            this.state.insurance_protection
          )
      ) {
        text += "<p>The Cash Down range have changed.</p>";
        flag = true;
      }
    }
    if (
      quot.retailer.credit_range.min_loan_length != null &&
      quot.retailer.credit_range.min_loan_length >= 0 &&
      quot.retailer.credit_range.max_loan_length != null &&
      quot.retailer.credit_range.max_loan_length >= 0
    ) {
      let max_loan_length =
        this.state.quot.retailer.credit_range.max_loan_length;
      if (this.state.quot.car.condition == "new") {
        max_loan_length += 12;
      }
      if (this.state.quot.car.condition == "used") {
        max_loan_length = 72;
        if (max_loan_length < this.state.quot.loan_length) {
          max_loan_length = this.state.quot.loan_length;
        }
      }
      if (
        this.state.quot.loan_length > max_loan_length ||
        this.state.quot.loan_length <
          this.state.quot.retailer.credit_range.min_loan_length
      ) {
        text += "<p>The Loan Length range have changed.</p>";
        flag = true;
      }
    }
    if (
      !quot.retailer.credit_range.insurance_protection &&
      quot.insurance_protection
    ) {
      text += "<p>The insurance and protection has been disabled.</p>";
      flag = true;
    }
    if (flag) {
      this.setState({
        confirmModal: "formula",
        changedValues:
          text + " <p>Do you want to edit your deal with the new values?</p>",
      });
      return;
    }
    this.setState({ editDisabled: false, showPdf: false });
  };

  editQuote = () => {
    let cox = this.state.cox;
    if (this.state.cox != null) {
      cox.amountFinanced = this.state.finalQuots.monthly.final_price;
    }
    const data = {
      formula_id: this.state.formula.id,
      car_id: this.state.car.id,
      monthly_payment: this.state.monthly_payment,
      loan_length: this.state.loan_length,
      down_payment: this.state.down_payment,
      credit_score_range: this.state.credit_score,
      type: this.state.paymentType,
      status: this.state.quot.status == 0 ? 1 : this.state.quot.status,
      insurance_protection: this.state.insurance_protection,
      trade_in:
        this.state.trade_in == null || this.state.trade_in == ""
          ? 0
          : this.state.trade_in,
      money_owed:
        this.state.money_owed == null || this.state.money_owed == ""
          ? 0
          : this.state.money_owed,
      plan: this.state.quot.plan ? true : false,
      cox: cox,
    };
    this.setState({ loading: true });
    editQuot(this.state.quot.id, data)
      .then((response) => {
        if (this.state.quot.status == 0) {
          if (this.props.insideDashboard) {
            this.props.updateCreatedQuots(1, this.state.quot.id, true);
          }
        }
        this.quoteState(response);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 422) {
            this.setState({
              loading: false,
              modal: {
                message: "Sorry. Fields are missing to save the information",
                status: 422,
              },
            });
          } else if (error.response.status == 423) {
            let quot = this.state.quot;
            quot.status = error.response.data.status;
            if (this.props.insideDashboard) {
              this.props.updateCreatedQuots(
                error.response.data.status,
                quot.id,
                false
              );
            }
            this.setState({
              loading: false,
              quot,
              editDisabled: true,
              modal: { status: 406, message: error.response.data.message },
            });
          } else if (error.response.status == 406) {
            this.setState({
              loading: false,
              confirmModal: "formula",
              formulaChanged: error.response.data.formula,
            });
          } else if (error.response.status != 403) {
            this.setState({
              loading: false,
              modal: {
                message: "Sorry. An error occurred, please try again later",
                status: 500,
              },
            });
          }
        }
      });
  };

  // ---------------------------- cancel edit ---------------------------------
  cancelEdit = () => {
    let quot = this.state.quot;
    let formula = this.state.quot.formula;
    let trade_in = quot.trade_in;
    let money_owed = quot.money_owed;
    let paymentType = quot.type;
    let down_payment = quot.down_payment;
    let loan_length = quot.loan_length;
    let credit_score = quot.credit_score_range;
    let insurance_protection = quot.insurance_protection;
    let interestRage = interestRageBuild(formula);
    let cox = null;
    // -------------------- get cox values
    if (quot.cox != null) {
      interestRage = quot.cox.interestRage;
      formula.tax_rate = quot.cox.tax_rate;
      formula.dealer_fees = quot.cox.dealer_fees;
      cox = Object.assign({}, quot.cox);
      if (
        cox?.incentives?.totalDealerCash > 0 ||
        cox?.incentives?.totalRebate > 0
      ) {
        formula.incentives = Object.assign({}, cox.incentives);
      }
    }
    // ---------- return cash down values
    let max_down_payment = getCarPrice(
      this.state.quot.car,
      this.state.quot.retailer.formula,
      insurance_protection
    );
    if (quot.cox != null) {
      max_down_payment = cox.amountFinanced;
    }
    let min_down_payment = 0;
    if (
      quot.retailer.credit_range.min_down_payment != null &&
      quot.retailer.credit_range.min_down_payment >= 0 &&
      quot.retailer.credit_range.max_down_payment != null &&
      quot.retailer.credit_range.max_down_payment > 0
    ) {
      max_down_payment = this.getRangeCashDown(
        quot.retailer.credit_range.max_down_payment,
        insurance_protection
      );
      min_down_payment = this.getRangeCashDown(
        quot.retailer.credit_range.min_down_payment,
        insurance_protection
      );
      if (down_payment > max_down_payment) {
        max_down_payment = down_payment;
      }
      if (down_payment < min_down_payment) {
        min_down_payment = down_payment;
      }
    }
    // ---------- return cash down values
    let max_loan_length = quot.retailer.credit_range.max_loan_length;
    let min_loan_length = quot.retailer.credit_range.min_loan_length;
    if (
      quot.retailer.credit_range.min_loan_length != null &&
      quot.retailer.credit_range.min_loan_length >= 0 &&
      quot.retailer.credit_range.max_loan_length != null &&
      quot.retailer.credit_range.max_loan_length > 0
    ) {
      if (loan_length > max_loan_length) {
        max_loan_length = loan_length;
      }
      if (loan_length < min_loan_length) {
        min_loan_length = loan_length;
      }
    }
    // ----------------
    let finalQuots = calculatePayments(
      this.state.car,
      loan_length,
      down_payment,
      formula,
      interestRage[credit_score],
      insurance_protection,
      trade_in,
      money_owed
    );
    let max_monthly_payment = this.state.aux_max_monthly;
    let min_monthly_payment = this.state.aux_min_monthly;
    this.setState({
      monthly_payment: finalQuots.monthly.payment,
      max_monthly_payment,
      min_monthly_payment,
      down_payment,
      loan_length,
      credit_score,
      insurance_protection,
      finalQuots,
      editDisabled: true,
      showPdf: true,
      paymentType,
      interestRage,
      formula,
      formulaChanged: {},
      trade_in,
      money_owed,
      max_down_payment,
      min_down_payment,
      cox,
    });
  };

  // ------------------------ upddate quote formula --------------------------
  updateFormula = () => {
    let updatedFormula = null;
    if (this.state.formulaChanged.id) {
      updatedFormula = this.state.formulaChanged;
    } else {
      updatedFormula = this.state.quot.retailer.formula;
    }
    if (this.state.quot.cox != null) {
      // -------------------- get cox values
      updatedFormula.tax_rate = this.state.quot.cox.tax_rate;
      updatedFormula.dealer_fees = this.state.quot.cox.dealer_fees;
      let cox = this.state.quot.cox;
      if (
        cox?.incentives?.totalDealerCash > 0 ||
        cox?.incentives?.totalRebate > 0
      ) {
        updatedFormula.incentives = Object.assign({}, cox.incentives);
      }
    }
    let formula = updatedFormula;
    let interestRage = this.state.interestRage;
    if (this.state.quot.cox == null) {
      interestRage = interestRageBuild(formula);
    }
    let quot = this.state.quot;
    let insurance_protection = quot.insurance_protection;
    if (
      !quot.retailer.credit_range.insurance_protection &&
      quot.insurance_protection
    ) {
      insurance_protection = false;
    }
    let max_down_payment = getCarPrice(
      this.state.quot.car,
      this.state.quot.retailer.formula,
      insurance_protection
    );
    if (quot.cox != null) {
      max_down_payment = quot.cox.amountFinanced;
    }
    let min_down_payment = 0;
    let down_payment = quot.down_payment;
    let max_loan_length = quot.retailer.credit_range.max_loan_length;
    let min_loan_length = quot.retailer.credit_range.min_loan_length;
    let loan_length = quot.loan_length;
    // ---------- validate cash down
    if (
      quot.retailer.credit_range.min_down_payment != null &&
      quot.retailer.credit_range.min_down_payment >= 0 &&
      quot.retailer.credit_range.max_down_payment != null &&
      quot.retailer.credit_range.max_down_payment > 0
    ) {
      max_down_payment = this.getRangeCashDown(
        quot.retailer.credit_range.max_down_payment,
        insurance_protection
      );
      min_down_payment = this.getRangeCashDown(
        quot.retailer.credit_range.min_down_payment,
        insurance_protection
      );
      if (down_payment < min_down_payment) {
        down_payment = min_down_payment;
      }
      if (down_payment > max_down_payment) {
        down_payment = max_down_payment;
      }
    }
    // --------- validate loan length
    if (
      quot.retailer.credit_range.min_loan_length &&
      quot.retailer.credit_range.max_loan_length != null &&
      quot.retailer.credit_range.max_loan_length > 0
    ) {
      if (quot.car.condition == "new") {
        max_loan_length += 12;
      }
      if (quot.car.condition == "used") {
        max_loan_length = 72;
        if (max_loan_length < quot.loan_length) {
          max_loan_length = quot.loan_length;
        }
      }
      if (loan_length < min_loan_length) {
        loan_length = min_loan_length;
      }
      if (loan_length > max_loan_length) {
        loan_length = max_loan_length;
      }
    }
    // ----------------------
    let finalQuots = calculatePayments(
      this.state.car,
      loan_length,
      min_down_payment,
      formula,
      interestRage[this.state.credit_score],
      insurance_protection,
      this.state.trade_in,
      this.state.money_owed
    );
    let max_monthly_payment = finalQuots.monthly.payment;
    finalQuots = calculatePayments(
      this.state.car,
      loan_length,
      max_down_payment,
      formula,
      interestRage[this.state.credit_score],
      insurance_protection,
      this.state.trade_in,
      this.state.money_owed
    );
    let min_monthly_payment = finalQuots.monthly.payment;
    finalQuots = calculatePayments(
      this.state.car,
      loan_length,
      down_payment,
      formula,
      interestRage[this.state.credit_score],
      insurance_protection,
      this.state.trade_in,
      this.state.money_owed
    );
    this.setState({
      formula,
      editDisabled: false,
      showPdf: false,
      confirmModal: false,
      max_monthly_payment,
      min_monthly_payment,
      monthly_payment: finalQuots.monthly.payment,
      finalQuots,
      interestRage,
      max_down_payment,
      min_down_payment,
      down_payment,
      loan_length,
      max_loan_length,
      min_loan_length,
      insurance_protection,
    });
  };

  // ---------------------------- cancel quote --------------------------------
  changeQuotStatus = (status) => {
    this.setState({ confirmModal: status });
  };

  confirmQuotStatus = () => {
    let data = {
      status: this.state.confirmModal,
    };
    this.setState({ loading: true, confirmModal: false });
    const success = (response) => {
      let quot = this.state.quot;
      quot.status = data.status;
      this.setState({
        loading: false,
        quot,
        modal: {
          status: 202,
          message:
            data.status == 3
              ? "The deal has been canceled"
              : "The deal has been accepted",
        },
      });
      if (this.props.insideDashboard) {
        this.props.updateCreatedQuots(data.status, quot.id, false);
      }
    };
    const errorResponse = (error) => {
      if (error.response) {
        if (error.response.status == 422) {
          this.setState({
            loading: false,
            modal: {
              message: "Sorry. Fields are missing to save the information",
              status: 422,
            },
          });
        } else if (error.response.status != 403) {
          this.setState({
            loading: false,
            modal: {
              message: "Sorry. An error occurred, please try again later",
              status: 500,
            },
          });
        }
      }
    };
    if (this.state.role == "Customer") {
      editQuotStatusCustomer(this.state.quot.id, data)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          errorResponse(error);
        });
    } else if (this.state.role == "Retailer") {
      editQuotStatusRetailer(this.state.quot.id, data)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  };

  // ---------------------------- change value state ---------------------------
  onChangeState = (value, label) => {
    this.setState({ [label]: value });
  };

  // ---------------------------- hide modal -----------------------------------
  hideModal = () => {
    this.setState({ modal: {} });
  };

  // ------------------------------ create pdf ----------------------------------
  pdf = () => {
    this.setState({ loading: true, hideElementsPdf: true }, () => {
      const input = document.getElementById("pdfCreated");
      html2canvas(input, {
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("P", "mm", "Letter");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 15, 15, width - 20, 0);
        pdf.save(
          this.state.car.year +
            " " +
            this.state.car.make +
            " " +
            this.state.car.model +
            "No." +
            this.state.quot.id +
            ".pdf"
        );
        this.setState({ loading: false, hideElementsPdf: false });
      });
    });
  };

  render() {
    return (
      <div
        className={
          this.props.quotId
            ? "grapper-datails"
            : "grapper-datails grapper-datails-background"
        }
      >
        {this.state.error.message ? (
          <Message error header="404" content={this.state.error.message} />
        ) : (
          this.state.quot.id && (
            <Grid id="pdfCreated">
              {!this.props.insideDashboard &&
                !this.props.adminUserList &&
                !this.state.hideElementsPdf && (
                  <div className="back-dashboard">
                    <h2 onClick={() => this.props.history.push("/login")}>
                      Go to Dashboard <Icon name="arrow right" />
                    </h2>
                  </div>
                )}

              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                style={{ paddingBottom: 0 }}
                className="bottom-padding"
              >
                {!this.state.hideElementsPdf ? (
                  <Car
                    type={"detailQuot"}
                    retailer={this.state.quot.retailer}
                    edit={this.state.editDisabled}
                    car={this.state.car}
                    interest={this.state.interestRage[this.state.credit_score]}
                    quots={this.state.finalQuots}
                    formula={this.state.formula}
                    trade_in={this.state.trade_in}
                    money_owed={this.state.money_owed}
                    max_down_payment={this.state.max_down_payment}
                    insuranceChange={this.onChangeState}
                    disabledTrade={this.state.editDisabled}
                  />
                ) : (
                  <div
                    className={this.state.hideElementsPdf ? "price-custom" : ""}
                  >
                    <Car
                      type={"onlyDetailInfo"}
                      retailer={this.state.quot.retailer}
                      edit={this.state.editDisabled}
                      car={this.state.car}
                      interest={
                        this.state.interestRage[this.state.credit_score]
                      }
                      quots={this.state.finalQuots}
                      formula={this.state.formula}
                      trade_in={this.state.trade_in}
                      money_owed={this.state.money_owed}
                      max_down_payment={this.state.max_down_payment}
                      insuranceChange={this.onChangeState}
                      disabledTrade={this.state.editDisabled}
                    />
                  </div>
                )}
              </Grid.Column>

              <Grid.Column mobile={16} tablet={16} computer={16}>
                <AdditionalInfo
                  state={{ ...this.state }}
                  onOpenBuyerModal={() => this.setState({ buyerModal: true })}
                  pdf={this.pdf}
                  onChangeState={this.onChangeState}
                  checkFormula={this.checkFormula}
                  cancelEdit={this.cancelEdit}
                  editQuote={this.editQuote}
                  changeQuotStatus={this.changeQuotStatus}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={16} computer={16}>
                <PaymentInfo
                  state={{ ...this.state }}
                  onChangeState={this.onChangeState}
                />
              </Grid.Column>
            </Grid>
          )
        )}

        {this.state.buyerModal && (
          <BuyerInformation
            customer={this.state.quot.customer}
            close={() => this.setState({ buyerModal: false })}
          />
        )}

        {this.state.confirmModal && (
          <ConfirmModal
            state={{ ...this.state }}
            setState={this.setState}
            confirmQuotStatus={this.confirmQuotStatus}
            updateFormula={this.updateFormula}
          />
        )}

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}

        {this.state.modal.message && (
          <ModalError hideModal={this.hideModal} modal={this.state.modal} />
        )}
      </div>
    );
  }
}

export default withRouter(QuotationDetails);
