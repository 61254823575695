import React, { useState } from "react";
import { Form } from "semantic-ui-react";

import Dropdown from "../../../global/DropDown";
import Input from "../../../global/Input";

export const PollFilterSection = (props) => {
  const { pollsBackUp } = props;
  const retailers = props.retailers.map((item) => {
    return {
      value: item.key,
      text: item.text,
    };
  });
  const [form, setForm] = useState({ retailer: "", keyword: "" });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    props.search(auxForm);
    setForm({ ...auxForm });
  };

  return (
    <Form className="search-form">
      <Form.Group>
        <Form.Field width={8}>
          <label>Retailer:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "retailer")}
            placeholder={"Retailer"}
            options={retailers}
            value={form.retailer}
            clearable
            selection
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>Keyword:</label>

          <Input
            onChange={(e) => onEditInput(e.target.value, "keyword")}
            placeholder={"Keyword"}
            value={form.keyword}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
