import React, { Component } from "react";
import { Form, Dimmer, Loader, Message } from "semantic-ui-react";
import cn from "classnames";

// IMPORT COMPONENTS
import Button from "../../global/Button";
import Input from "../../global/Input";
import AlertModal from "../alertModal/modal";
import CodeModal from "../codeModal/codeModal";

import "./resetPassword.sass";
import { checkPhone, resetPassword } from "../requests";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      phone_number: "",
      password: "",
      confirmPassword: "",
      completeForm: false,
      code: "",
      error: {},
      loading: false,
      validCode: false,
      msnErrors: {
        phone_number: "",
        password: "",
        confirmPassword: "",
      },
      modal: {},
      disabled: false,
    };
    this.onEditInput = this.onEditInput.bind(this);
  }

  switchLabel(label, value, msnErrors) {
    switch (label) {
      case "phone_number":
        msnErrors.phone_number =
          value.length == "" ? "Phone number is required" : "";
        break;
      case "password":
        msnErrors.password =
          value.length < 8 ? "Password must be at least 8 characters long" : "";
        break;
      case "confirmPassword":
        msnErrors.confirmPassword =
          value != this.state.password ? "Passwords do not match" : "";
        break;
      default:
        break;
    }
    return msnErrors;
  }

  validateForm = () => {
    let valid = true;
    let errors = this.state.msnErrors;
    let array = [];
    if (this.state.step == 0) {
      array = ["phone_number"];
    } else {
      array = ["password", "confirmPassword"];
    }
    for (const iterator of array) {
      errors = this.switchLabel(iterator, this.state[iterator], errors);
    }
    for (let i = 0; i < array.length; i++) {
      if (errors[array[i]].length > 0) {
        valid = false;
      }
    }
    this.setState({ completeForm: valid });
    return valid;
  };

  onEditInput(label, value) {
    let msnErrors = this.state.msnErrors;
    msnErrors = this.switchLabel(label, value, msnErrors);
    this.setState({ [label]: value, msnErrors }, () => this.validateForm());
  }

  // ------------------------------ check phone number ----------------------------
  continue = () => {
    this.setState({ loading: true, disabled: true });
    let data = {
      phone_number: this.state.phone_number,
    };
    if (this.state.validCode) {
      data["code"] = this.state.code;
    }
    checkPhone(data)
      .then((response) => {
        console.log(response);
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({ validCode: true, disabled: false });
        } else {
          this.setState({ validCode: false, completeForm: false, step: 1 });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        if (error.response) {
          if (error.response.status === 422) {
            this.setState({
              loading: false,
              error: {
                status: 422,
                message: "Sorry. Fields are missing to save your information",
              },
            });
          } else if (error.response.status === 404) {
            this.setState({
              loading: false,
              error: {
                status: 404,
                message: error.response.data.message,
              },
            });
          } else if (error.response.status === 400) {
            this.setState({
              loading: false,
              modal: {
                status: 400,
                message: error.response.data.message,
              },
            });
          } else {
            this.setState({
              loading: false,
              validCode: false,
              error: {
                status: 500,
                message: "Sorry. An error occurred, please try again later",
              },
            });
          }
        }
        console.log(error.response);
      });
  };
  // --------------------------------------------------- update password ------------------------------------
  save = () => {
    this.setState({ loading: true });
    let data = {
      phone_number: this.state.phone_number,
      password: this.state.password,
      password_confirmation: this.state.confirmPassword,
    };
    resetPassword(data)
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          modal: {
            status: 200,
            message: "Password was updated",
          },
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          if (error.response.status === 422) {
            this.setState({
              loading: false,
              error: {
                status: 422,
                message: "Sorry. Fields are missing to save your information",
              },
            });
          } else if (error.response.status === 404) {
            this.setState({
              loading: false,
              error: {
                status: 404,
                message: error.response.data.message,
              },
            });
          } else {
            this.setState({
              loading: false,
              error: {
                status: 500,
                message: "Sorry. An error occurred, please try again later",
              },
            });
          }
        }
        console.log(error.response);
      });
  };

  hideModal = () => {
    this.setState({ modal: {} });
    // --------------- return to login after change password ----------------------
    if (this.state.modal.status === 200) {
      this.props.returnLogin();
    }
    this.setState({ modal: {}, disabled: false });
  };

  render() {
    return (
      <div className="login-body">
        <h2
          className={cn(
            "title",
            this.props.prequalified && "prequalifiedTitle"
          )}
        >
          Reset Password
        </h2>
        <p>Give us more details about your account.</p>

        {this.state.error.message && (
          <Message
            error
            header="Reset Password Failed"
            content={this.state.error.message}
          />
        )}

        <div>
          {this.state.step == 0 ? (
            <div>
              <Form.Field className={"phoneNumberWrapper"}>
                <label
                  style={{
                    display: "block",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  Enter your phone number.
                </label>

                <Input
                  label="+1"
                  value={this.state.phone_number}
                  placeholder="Phone Number"
                  onChange={(e) =>
                    this.onEditInput("phone_number", e.target.value)
                  }
                  maxLength="15"
                  min="12"
                  disabled={this.state.flagEdit}
                  error={
                    this.state.msnErrors.phone_number.length > 0
                      ? this.state.msnErrors.phone_number
                      : null
                  }
                  noMargin
                />
              </Form.Field>
            </div>
          ) : (
            <div>
              <Form.Input
                value={this.state.password}
                label={<label>New Password</label>}
                placeholder={"Enter New Password"}
                onChange={(e) => this.onEditInput("password", e.target.value)}
                width={16}
                fluid
                type="password"
                error={
                  this.state.msnErrors.password.length > 0
                    ? this.state.msnErrors.password
                    : null
                }
                maxLength="100"
                disabled={this.state.flagEdit}
              />
              <Form.Input
                value={this.state.confirmPassword}
                label={<label>Confirm New Password</label>}
                placeholder={"Confirm New Password"}
                onChange={(e) =>
                  this.onEditInput("confirmPassword", e.target.value)
                }
                width={16}
                fluid
                type="password"
                error={
                  this.state.msnErrors.confirmPassword.length > 0
                    ? this.state.msnErrors.confirmPassword
                    : null
                }
                maxLength="100"
                disabled={this.state.flagEdit}
              />
            </div>
          )}
          <p className="p-forgot" onClick={this.props.returnLogin}>
            Return to login
          </p>

          <div className={"buttonWrapper"}>
            <Button
              buttonStyle="skyblue"
              className={cn(
                "btn-continue",
                this.props.prequalified && "prequalifiedButton"
              )}
              onClick={this.state.step == 1 ? this.save : this.continue}
              disabled={!this.state.completeForm || this.state.loading}
            >
              {this.state.step == 0 ? "Continue" : "Save"}
            </Button>
          </div>
        </div>
        {this.state.validCode ? (
          <CodeModal
            logged={false}
            phone={this.state.phone_number}
            reset
            updateCode={this.onEditInput}
            action={this.continue}
            hideModalCode={() => this.setState({ validCode: false })}
            disabled={this.state.disabled}
          />
        ) : null}
        {this.state.loading ? (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        ) : null}
        {this.state.modal.message ? (
          <AlertModal hideModal={this.hideModal} modal={this.state.modal} />
        ) : null}
      </div>
    );
  }
}

export default ResetPassword;
