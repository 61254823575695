// IMPORT OBJECTS/LIBRARIES
import React, { Component } from "react";
import { Segment, Grid, Header, Button, Popup, Icon } from "semantic-ui-react";
import Slider from "rc-slider";
import {
  FormatNumberToPrice,
  calculatePayments,
  calculateDownPayment,
  FormatCashDownToPrice,
  calculateMinDownPayment,
  calculateMaxDownPayment,
} from "../../helpers/general";
import Input from "../../global/Input";
import cn from "classnames";

// IMPORT CSS STYLE
import "./sliders.sass";

const Handle = Slider.Handle;
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Handle value={value} {...restProps}>
      <div className="inner">
        <div className={`wdc-tooltip${dragging ? " active" : ""}`}>
          <span className="wdc-tooltip-inner">{value}</span>
        </div>
      </div>
    </Handle>
  );
};

class Sliders extends Component {
  constructor(props) {
    super(props);
    let downMarks = {};

    for (let i = props.min_loan_length; i <= 84; i++) {
      if (i % 12 == 0) {
        downMarks[i] = i + " mo";
      }
    }
    downMarks[props.max_loan_length] = props.max_loan_length + " mo";
    this.state = {
      // ---------------------- slider values --------------
      trade_in: props.trade_in,
      money_owed: props.money_owed,
      monthly_payment: props.monthly_payment,
      formatMonthly: parseFloat(props.monthly_payment).toFixed(2),
      steps_monthly_payment: props.steps_monthly_payment,
      min_monthly_payment: props.min_monthly_payment,
      max_monthly_payment: props.max_monthly_payment,
      loan_length: props.loan_length,
      min_down_payment: props.min_down_payment,
      max_down_payment: props.max_down_payment,
      downMarks: downMarks,
      min_loan_length: props.min_loan_length,
      max_loan_length: 84,
      down_payment: props.down_payment,
      credit_score: props.credit_score,
      step_credit_range: props.credit_range.min - props.credit_range.max,
      credit_range: props.credit_range,
      // ------------------------ formula information -------------------
      formula: props.formula,
      // ------------------------ format payment numbers ------------
      isTypingMonthly: false,
      isTypingDown: false,
      finalQuots: props.finalQuots,
      interestRage: props.interestRage,
      insurance_protection: props.insurance_protection,
      // ------------------------ info car -------------------
      car: props.step <= 2 ? props.car : props.selectedCar,
      steps: {},
    };
  }

  componentDidMount() {
    if (this.props.showPayment) {
      // this.callDetail();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showPayment &&
      this.props.money_owed != prevProps.money_owed
    ) {
      if (!this.props.noCalculate) {
        this.callDetail("money_owed");
      } else {
        this.setState({ money_owed: this.props.money_owed });
      }
    } else if (this.props.trade_in !== prevProps.trade_in) {
      if (!this.props.noCalculate) {
        this.callDetail("trade");
      } else {
        this.setState({ trade_in: this.props.trade_in });
      }
    } else if (
      this.props.showPayment &&
      this.props.insurance_protection != prevProps.insurance_protection
    ) {
      this.callDetail("insurance");
    }
    if (
      this.props.reset &&
      (this.props.monthly_payment !== prevProps.monthly_payment ||
        this.props.credit_score !== prevProps.credit_score ||
        this.props.loan_length !== prevProps.loan_length ||
        this.props.down_payment !== prevProps.down_payment)
    ) {
      this.setState({
        monthly_payment: this.props.monthly_payment,
        credit_score: this.props.credit_score,
        loan_length: this.props.loan_length,
        down_payment: this.props.down_payment,
        max_monthly_payment: this.props.max_monthly_payment,
        min_monthly_payment: this.props.min_monthly_payment,
      });
    }
  }

  callDetail = (type) => {
    let max_down_payment = this.props.max_down_payment;
    let max_monthly_payment = this.props.max_monthly_payment;
    let monthly_payment, formatMonthly;
    let min_monthly_payment = this.props.min_monthly_payment;
    let min_down_payment = this.props.min_down_payment;
    let down_payment = this.props.down_payment;
    // let trade_in = type === 'trade' ? 0 : this.props.trade_in;
    let trade_in = this.props.trade_in,
      money_owed = +this.props.money_owed;
    if (+this.props.trade_in >= +this.props.money_owed) {
      money_owed = 0;
      trade_in = 0;
    }
    let auxQuotes = calculatePayments(
      this.state.car,
      this.props.loan_length,
      0,
      this.props.formula,
      this.props.interestRage[this.props.credit_score],
      this.props.insurance_protection,
      trade_in,
      money_owed
    );
    max_down_payment = calculateMaxDownPayment(
      auxQuotes.monthly.final_price,
      this.props.retailer_credit_range
    );
    min_down_payment = calculateMinDownPayment(
      auxQuotes.monthly.final_price,
      this.props.retailer_credit_range
    );
    if (down_payment > max_down_payment) {
      down_payment = max_down_payment;
    }
    if (down_payment < min_down_payment) {
      down_payment = min_down_payment;
    }
    auxQuotes = calculatePayments(
      this.state.car,
      this.props.loan_length,
      min_down_payment,
      this.props.formula,
      this.props.interestRage[this.props.credit_score],
      this.props.insurance_protection,
      trade_in,
      money_owed
    );
    max_monthly_payment = auxQuotes.monthly.payment;
    let finalQuots = calculatePayments(
      this.state.car,
      this.props.loan_length,
      down_payment,
      this.props.formula,
      this.props.interestRage[this.props.credit_score],
      this.props.insurance_protection,
      this.props.trade_in,
      +this.props.money_owed
    );
    monthly_payment = finalQuots.monthly.payment;
    formatMonthly = parseFloat(monthly_payment).toFixed(2);
    min_monthly_payment = calculatePayments(
      this.state.car,
      this.props.loan_length,
      max_down_payment,
      this.props.formula,
      this.props.interestRage[this.props.credit_score],
      this.props.insurance_protection,
      this.props.trade_in,
      +this.props.money_owed
    ).monthly.payment;
    let steps_monthly_payment = {
      0: "$0",
      [max_monthly_payment]: FormatNumberToPrice(max_monthly_payment),
    };
    this.props.quotation(min_monthly_payment, "min_monthly_payment");
    this.props.quotation(max_monthly_payment, "max_monthly_payment");
    this.props.quotation(steps_monthly_payment, "steps_monthly_payment");
    this.props.quotation(monthly_payment, "monthly_payment");
    this.props.quotation(max_down_payment, "max_down_payment");
    this.props.quotation(min_down_payment, "min_down_payment");
    this.props.quotation(down_payment, "down_payment");
    this.props.quotation(finalQuots, "finalQuots");
    this.setState({
      monthly_payment,
      max_monthly_payment,
      steps_monthly_payment,
      insurance_protection: this.props.insurance_protection,
      trade_in: this.props.trade_in,
      down_payment,
      max_down_payment,
      min_down_payment,
      min_monthly_payment,
      formatMonthly,
      money_owed: this.props.money_owed,
      cox: this.props.cox,
      interestRage: this.props.interestRage,
    });
  };

  // ---------------------------- change value state ---------------------------
  onChangeState = (value, label) => {
    let flagTrade = false;
    let max_monthly_payment = this.state.max_monthly_payment;
    let min_monthly_payment = this.state.min_monthly_payment;
    let trade_in = this.state.trade_in;
    let steps_monthly_payment = this.state.steps_monthly_payment;
    let monthly_payment = this.state.monthly_payment,
      formatMonthly = this.state.formatMonthly;
    let steps = this.state.steps;
    let down_payment = this.state.down_payment;
    let flagDown = false;
    if (label == "monthly_payment" && value.target) {
      let { min, max } = value.target;
      let val = value.target.value;
      value = Math.max(Number(min), Math.min(Number(max), Number(val)));
    }
    if (label == "monthly_payment") {
      if (this.state.car.id) {
        this.recalcualtePayment(value);
      } else {
        this.props.quotation(value, "monthly_payment");
        this.setState({
          [label]: value,
          formatMonthly: parseFloat(value).toFixed(2),
        });
      }
      return;
    }
    let finalQuots = this.state.finalQuots;
    if (label == "down_payment" && value.target) {
      let { min, max } = value.target;
      let val = value.target.value;
      value = Math.max(Number(min), Math.min(Number(max), Number(val)));
    }
    if (label == "loan_length" && value.target) {
      let { min, max } = value.target;
      let val = value.target.value;
      value = Math.max(Number(min), Math.min(Number(max), Number(val)));
    }
    if (label == "loan_length" && this.state.car.id) {
      finalQuots = calculatePayments(
        this.state.car,
        value,
        this.state.min_down_payment,
        this.state.formula,
        this.state.interestRage[this.state.credit_score],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      );
      max_monthly_payment = finalQuots.monthly.payment;
      finalQuots = calculatePayments(
        this.state.car,
        value,
        this.state.down_payment,
        this.state.formula,
        this.state.interestRage[this.state.credit_score],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      );
      min_monthly_payment = calculatePayments(
        this.state.car,
        value,
        this.state.max_down_payment,
        this.state.formula,
        this.state.interestRage[this.state.credit_score],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      ).monthly.payment;
      monthly_payment = finalQuots.monthly.payment;
      formatMonthly = parseFloat(monthly_payment).toFixed(2);
    }
    if (label == "down_payment" && this.state.car.id) {
      if (value > this.state.max_down_payment) {
        value = this.state.max_down_payment;
      }
      finalQuots = calculatePayments(
        this.state.car,
        this.state.loan_length,
        value,
        this.state.formula,
        this.state.interestRage[this.state.credit_score],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      );
      monthly_payment = finalQuots.monthly.payment;
      formatMonthly = parseFloat(monthly_payment).toFixed(2);
      flagDown = false;
    }
    if (label == "credit_score" && this.state.car.id) {
      finalQuots = calculatePayments(
        this.state.car,
        this.state.loan_length,
        this.state.min_down_payment,
        this.state.formula,
        this.state.interestRage[value],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      );
      max_monthly_payment = finalQuots.monthly.payment;
      finalQuots = calculatePayments(
        this.state.car,
        this.state.loan_length,
        this.state.down_payment,
        this.state.formula,
        this.state.interestRage[value],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      );
      min_monthly_payment = calculatePayments(
        this.state.car,
        this.state.loan_length,
        this.state.max_down_payment,
        this.state.formula,
        this.state.interestRage[value],
        this.state.insurance_protection,
        this.state.trade_in,
        this.state.money_owed
      ).monthly.payment;
      monthly_payment = finalQuots.monthly.payment;
      formatMonthly = parseFloat(monthly_payment).toFixed(2);
    }
    this.props.quotation(max_monthly_payment, "max_monthly_payment");
    this.props.quotation(min_monthly_payment, "min_monthly_payment");
    this.props.quotation(monthly_payment, "monthly_payment");
    this.props.quotation(steps_monthly_payment, "steps_monthly_payment");
    this.props.quotation(value, label);
    this.props.quotation(finalQuots, "finalQuots");
    if (flagDown) {
      this.props.quotation(down_payment, "down_payment");
      this.setState({ down_payment });
    }
    this.setState(
      {
        [label]: value,
        max_monthly_payment,
        min_monthly_payment,
        steps_monthly_payment,
        steps,
        monthly_payment,
        formatMonthly,
        finalQuots,
        trade_in,
      },
      () => {
        if (flagTrade) {
          this.props.quotation(trade_in, "trade_in");
        }
      }
    );
  };

  recalcualtePayment(value) {
    let monthly_payment = 0;
    if (value < this.state.min_monthly_payment) {
      value = this.state.min_monthly_payment;
    }
    if (value > this.state.max_monthly_payment) {
      value = this.state.max_monthly_payment;
    }
    let trade_in = this.state.trade_in;
    let down_payment = calculateDownPayment(
      this.state.car,
      this.state.loan_length,
      this.state.formula,
      this.state.interestRage[this.state.credit_score],
      this.state.insurance_protection,
      value,
      trade_in,
      this.state.money_owed
    );
    if (down_payment <= this.state.min_down_payment) {
      down_payment = this.state.min_down_payment;
    }
    let finalQuots = calculatePayments(
      this.state.car,
      this.state.loan_length,
      down_payment,
      this.state.formula,
      this.state.interestRage[this.state.credit_score],
      this.state.insurance_protection,
      trade_in,
      this.state.money_owed
    );
    monthly_payment = value;
    let formatMonthly = parseFloat(monthly_payment).toFixed(2);
    this.props.quotation(down_payment, "down_payment");
    this.props.quotation(value, "monthly_payment");
    this.props.quotation(finalQuots, "finalQuots");
    this.setState({ monthly_payment, finalQuots, down_payment, formatMonthly });
  }

  handleChangeInput = (event, label) => {
    // let { value, min, max } = event.target;
    // value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    this.props.quotation(event.target.value, label);
    this.setState({ [label]: event.target.value });
  };

  render() {
    return (
      <Grid
        className={cn("container-slider", this.props.showPayment && "editable")}
        id="hrefFocus"
      >
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={this.props.vertical ? 16 : 8}
          className="colum-slider"
        >
          <Segment className="segmentGrapper">
            <div className="header-slider">
              <Header as="h3">Monthly Payment</Header>
              {!this.props.disabled ? (
                this.state.isTypingMonthly ? (
                  <Input
                    min={this.state.min_monthly_payment}
                    max={this.state.max_monthly_payment}
                    onChange={(event) =>
                      this.handleChangeInput(event, "formatMonthly")
                    }
                    onBlur={(event) => {
                      this.onChangeState(event, "monthly_payment");
                      this.setState({
                        isTypingMonthly: !this.state.isTypingMonthly,
                      });
                    }}
                    value={this.state.formatMonthly}
                    type="number"
                    noMargin
                  />
                ) : (
                  <Input
                    min={this.state.min_monthly_payment}
                    max={this.state.max_monthly_payment}
                    value={FormatNumberToPrice(this.state.monthly_payment)}
                    onFocus={() =>
                      this.setState({
                        isTypingMonthly: !this.state.isTypingMonthly,
                      })
                    }
                    readOnly
                    noMargin
                  />
                )
              ) : (
                <span className="only-text">
                  {FormatNumberToPrice(
                    this.props.showPayment
                      ? this.props.finalQuots.monthly.payment
                      : this.state.monthly_payment
                  )}
                </span>
              )}
            </div>
            <div className="sliderGrapper">
              <Slider
                min={parseInt(this.state.min_monthly_payment)}
                max={this.state.max_monthly_payment}
                value={this.state.monthly_payment}
                step={2}
                onChange={(value) =>
                  this.onChangeState(value, "monthly_payment")
                }
                handleStyle={{
                  backgroundColor: "white",
                  marginBottom: 10,
                }}
                handle={handle}
                disabled={this.props.disabled ? true : false}
                tipProps={{
                  placement: "bottom",
                  overlayClassName: "foo",
                  prefixCls: "rc-slider-tooltip",
                }}
                marks={{
                  [parseInt(this.state.min_monthly_payment)]:
                    FormatNumberToPrice(
                      parseInt(this.state.min_monthly_payment)
                    ),
                  [this.state.max_monthly_payment]: FormatNumberToPrice(
                    this.state.max_monthly_payment
                  ),
                }}
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={this.props.vertical ? 16 : 8}
          className="colum-slider"
        >
          <Segment className="segmentGrapper">
            <div className="ui-computer">
              <div className="header-slider">
                <Header as="h3">
                  Cash Down
                  <Popup
                    hoverable
                    content={
                      <div>
                        Based on you credit score banks like to see 10-20% down.
                      </div>
                    }
                    trigger={
                      <Icon className="infoIcon" link name="info circle" />
                    }
                  />
                </Header>
                {!this.props.disabled ? (
                  this.state.isTypingDown ? (
                    <Input
                      min={this.state.min_down_payment}
                      max={this.state.max_down_payment}
                      value={parseInt(this.state.down_payment)}
                      type="number"
                      onChange={(event) =>
                        this.handleChangeInput(event, "down_payment")
                      }
                      onBlur={(event) => {
                        this.onChangeState(event, "down_payment");
                        this.setState({
                          isTypingDown: !this.state.isTypingDown,
                        });
                      }}
                      noMargin
                    />
                  ) : (
                    <Input
                      min={this.state.min_down_payment}
                      max={this.state.max_down_payment}
                      value={FormatCashDownToPrice(
                        parseInt(this.state.down_payment)
                      )}
                      onFocus={() =>
                        this.setState({
                          isTypingDown: !this.state.isTypingDown,
                        })
                      }
                      readOnly
                      noMargin
                    />
                  )
                ) : (
                  <span className="only-text">
                    {FormatCashDownToPrice(parseInt(this.state.down_payment))}
                  </span>
                )}
                <Header as="h3" style={{ marginTop: 0 }}>
                  + Trade=
                </Header>
                {!this.props.disabled ? (
                  <Input
                    value={FormatCashDownToPrice(
                      parseInt(
                        +this.state.down_payment +
                          (+this.state.trade_in - +this.state.money_owed < 0
                            ? 0
                            : +this.state.trade_in - +this.state.money_owed)
                      )
                    )}
                    disabled
                    noMargin
                  />
                ) : (
                  <span className="only-text">
                    {FormatCashDownToPrice(
                      parseInt(
                        +this.state.down_payment +
                          (+this.state.trade_in - +this.state.money_owed < 0
                            ? 0
                            : +this.state.trade_in - +this.state.money_owed)
                      )
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className="ui-mobile">
              <div className="header-slider headers-inside">
                <div className="header-inside">
                  <Header as="h3">
                    Cash Down
                    <Popup
                      hoverable
                      content={
                        <div>
                          Based on you credit score banks like to see 10-20%
                          down.
                        </div>
                      }
                      trigger={
                        <Icon className="infoIcon" link name="info circle" />
                      }
                    />
                  </Header>
                  {!this.props.disabled ? (
                    this.state.isTypingDown ? (
                      <Input
                        min={this.state.min_down_payment}
                        max={this.state.max_down_payment}
                        value={parseInt(this.state.down_payment)}
                        type="number"
                        onChange={(event) =>
                          this.handleChangeInput(event, "down_payment")
                        }
                        onBlur={(event) => {
                          this.onChangeState(event, "down_payment");
                          this.setState({
                            isTypingDown: !this.state.isTypingDown,
                          });
                        }}
                        noMargin
                      />
                    ) : (
                      <Input
                        min={this.state.min_down_payment}
                        max={this.state.max_down_payment}
                        value={FormatCashDownToPrice(
                          parseInt(this.state.down_payment)
                        )}
                        onFocus={() =>
                          this.setState({
                            isTypingDown: !this.state.isTypingDown,
                          })
                        }
                        readOnly
                        noMargin
                      />
                    )
                  ) : (
                    <span className="only-text">
                      {FormatCashDownToPrice(parseInt(this.state.down_payment))}
                    </span>
                  )}
                </div>
                <div className="header-inside">
                  <Header as="h3" style={{ marginTop: 0 }}>
                    + Trade=
                  </Header>
                  {!this.props.disabled ? (
                    <Input
                      value={FormatCashDownToPrice(
                        parseInt(
                          +this.state.down_payment + +this.state.trade_in
                        )
                      )}
                      disabled
                      noMargin
                    />
                  ) : (
                    <span className="only-text">
                      {FormatCashDownToPrice(
                        parseInt(
                          +this.state.down_payment + +this.state.trade_in
                        )
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="sliderGrapper">
              <Slider
                min={this.state.min_down_payment}
                max={parseInt(this.state.max_down_payment)}
                value={this.state.down_payment}
                step={100}
                onChange={(value) => this.onChangeState(value, "down_payment")}
                handleStyle={{
                  backgroundColor: "white",
                }}
                handle={handle}
                tipProps={{
                  placement: "bottom",
                  overlayClassName: "foo",
                  prefixCls: "rc-slider-tooltip",
                }}
                disabled={this.props.disabled ? true : false}
                marks={{
                  [this.state.min_down_payment]: FormatCashDownToPrice(
                    parseInt(this.state.min_down_payment)
                  ),
                  [this.state.max_down_payment]: FormatCashDownToPrice(
                    parseInt(this.state.max_down_payment)
                  ),
                }}
              />
            </div>
          </Segment>
        </Grid.Column>
        {/* <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="reset-wrapper"
        >
          {this.props.finalQuots.monthly &&
            this.props.finalQuots.monthly.carid && (
              <Header
                className="a2uWeekly"
                style={this.props.reset ? null : { top: 0 }}
              >
                Weekly:{" "}
                {FormatNumberToPrice(this.props.finalQuots.weekly.payment)}
                <WeeklyTooltip finalQuots={this.props.finalQuots} />
              </Header>
            )}
          {this.props.reset && (
            <Button
              className="btn-return"
              onClick={this.props.resetSilderValues}
            >
              Return to my <strong>Approved Pre-Qualification</strong> settings
            </Button>
          )}
        </Grid.Column> */}
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={this.props.vertical ? 16 : 8}
          className="colum-slider"
        >
          <Segment className="segmentGrapper">
            <div className="header-slider">
              <Header as="h3">Credit Score Range</Header>
              <span className="only-text">
                {this.state.credit_range.ranges[this.state.credit_score]}
              </span>
            </div>
            <div className="sliderGrapper sliderScore">
              <Slider
                min={0}
                max={4}
                step={this.state.step_credit_range}
                value={this.state.credit_score}
                onChange={(value) => this.onChangeState(value, "credit_score")}
                disabled={this.props.disabled ? true : false}
                tipProps={{
                  placement: "bottom",
                  overlayClassName: "foo",
                  prefixCls: "rc-slider-tooltip",
                }}
                marks={this.state.credit_range.ranges}
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={this.props.vertical ? 16 : 8}
          className="colum-slider"
        >
          <Segment
            className="segmentGrapper"
            style={{ backgroundColor: "white" }}
          >
            <div className="header-slider">
              <Header as="h3">Loan Length</Header>
              {!this.props.disabled ? (
                <Input
                  min={this.state.min_loan_length}
                  max={this.state.max_loan_length}
                  value={this.state.loan_length}
                  onChange={(event) =>
                    this.handleChangeInput(event, "loan_length")
                  }
                  onBlur={(event) => this.onChangeState(event, "loan_length")}
                  type="number"
                  noMargin
                />
              ) : (
                <span className="only-text">
                  {this.state.loan_length + " mo"}
                </span>
              )}
            </div>
            <div className="sliderGrapper" style={{ backgroundColor: "white" }}>
              <Slider
                min={this.state.min_loan_length}
                max={this.state.max_loan_length}
                step={1}
                value={this.state.loan_length}
                onChange={(value) => this.onChangeState(value, "loan_length")}
                handleStyle={{
                  backgroundColor: "white",
                }}
                handle={handle}
                disabled={this.props.disabled ? true : false}
                tipProps={{
                  placement: "bottom",
                  overlayClassName: "foo",
                  prefixCls: "rc-slider-tooltip",
                }}
                marks={this.state.downMarks}
              />
            </div>
            {/* {this.state.car.condition === "used" && (
              <small>
                NOTE: For used cars it is only allowed to select a maximum of 72
                months
              </small>
            )} */}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Sliders;
