import React, { useState, useEffect } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Thumbs } from "swiper";
import _ from "lodash";
import { useMediaPredicate } from "react-media-hook";

import SwiperCard from "./SwiperCard";
import SwiperButton from "./SwiperCard/SwiperButton";

import "./Swiper.sass";
import 'swiper/css';
import 'swiper/css/navigation'; // Optional, depending on features
import 'swiper/css/pagination'; // Optional, depending on features

const Swiper = ({ slides, selectedPlan, onSelect }) => {
  const [items, setItems] = useState([]);

  const is768 = useMediaPredicate("(max-width: 768px)");

  useEffect(() => {
    const dupedSlides = slides
      .map(() =>
        Array.from(slides, (item) => ({
          type: item,
          id: _.uniqueId("card"),
        }))
      )
      .flat(1);

    setItems(dupedSlides);
  }, []);

  return (
    <div className={"swiperAligner"}>
      <div className={"swiperWrapper"}>
        <SwiperComponent
          loop={true}
          slidesPerView={is768 ? 1 : slides.length}
          centeredSlides={true}
          grabCursor={true}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 0,
            slideShadows: false,
          }}
          modules={[EffectCoverflow, Thumbs]}
          onAfterInit={(swiper) => {
            if (selectedPlan) {
              const index = slides.findIndex((item) => item === selectedPlan);

              swiper.slideTo(index);
            } else {
              swiper.slideTo(1);
            }
          }}
        >
          {is768 && <SwiperButton action={"prev"} />}

          {items.map((item) => (
            <SwiperSlide key={item.id} className={"slideWrapper"}>
              {({ isActive, isNext, isPrev }) => (
                <SwiperCard
                  isActive={isActive}
                  item={item}
                  isPrev={isPrev}
                  isNext={isNext}
                  selectedPlan={selectedPlan}
                  isSelected={item.type === selectedPlan}
                  onClick={onSelect}
                />
              )}
            </SwiperSlide>
          ))}

          {is768 && <SwiperButton action={"next"} />}
        </SwiperComponent>
      </div>
    </div>
  );
};

export default Swiper;
