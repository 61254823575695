import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import { Dropdown as SemanticDropdown } from "semantic-ui-react";

import EmptyIcon from "../../../images/empty-icon.svg";

import "./styles.sass";

const Dropdown = ({
  options,
  className,
  disabled,
  error,
  onChange,
  value,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const menuRef = useRef();
  const dropdownRef = useRef();

  const onToggle = () => {
    setOpen(!open);
    setFilteredOptions(options);
  };

  const closeDropdown = () => setOpen(false);

  const handleOnSelectOption = (e, data) => {
    onChange && onChange(e, data);
    closeDropdown();
  };

  const handleOnSearch = (e, data) => {
    e.persist();

    const query = e.target.value.toLowerCase();

    setFilteredOptions(
      data.options.filter((o) => o.text.toLowerCase().includes(query))
    );
  };

  const getSelectedOption = () =>
    options.find((option) => option.value === value)?.text;

  useEffect(() => {
    const handler = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        !dropdownRef.current.contains(e.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handler);

    const bounds = dropdownRef.current.getBoundingClientRect();

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [open]);

  return (
    <div
      className={cn(
        "customDropdownWrapper",
        disabled && "ghost",
        error && "danger",
        !value && "placeholderAppearance",
        className
      )}
      ref={dropdownRef}
    >
      <SemanticDropdown
        disabled={disabled}
        onClick={onToggle}
        noResultsMessage={null}
        open={false}
        text={value ? getSelectedOption() : null}
        placeholder={rest.placeholder}
        onSearchChange={handleOnSearch}
        options={options}
        {...rest}
      />

      {open && (
        <SemanticDropdown.Menu>
          <div ref={menuRef}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => {
                return (
                  <SemanticDropdown.Item
                    key={option.value}
                    text={option.text}
                    value={option.value}
                    selected={value === option.value}
                    onClick={handleOnSelectOption}
                  />
                );
              })
            ) : (
              <div className={"emptyResult"}>
                <img src={EmptyIcon} alt={"Empty Icon"} />

                <span>Search result is empty</span>
              </div>
            )}
          </div>
        </SemanticDropdown.Menu>
      )}

      <p className={cn("errorMessage", error && "errorMessageVisible")}>
        {error}
      </p>
    </div>
  );
};

export default Dropdown;
