import React from "react";
import { Grid, Image, Card, Pagination } from "semantic-ui-react";

import { AddRetailer } from "../../utilities/retailerDetails/AddRetailer";
import { CreditSolutionModal } from "../../utilities/creditSolutionRequests/CreditSolutionModal";
import VariablesModal from "../../utilities/variablesModal/variablesModal";

import Button from "../../global/Button";

const Retailers = (props) => {
  return (
    <div
      className={"body"}
      style={{ display: props.activeItem == "retailers" ? "block" : "none" }}
    >
      <Grid className="ui-mobile">
        <AddRetailer addNewRetailer={props.addNewRetailer} />
      </Grid>

      <Grid columns={4} stackable>
        {props.state.retailers.map((retailer, k) => (
          <Grid.Column>
            <Card>
              {retailer.logo == null || retailer.logo == "" ? (
                <Image
                  src={require("../../../images/no-image-available.png")}
                />
              ) : (
                <Image src={retailer.logo} wrapped ui={false} />
              )}

              <Card.Content>
                <Card.Header>{retailer.name}</Card.Header>

                <Card.Meta>
                  <span className="date">
                    Number of deals: {retailer.quots_count}
                  </span>
                </Card.Meta>
              </Card.Content>

              <Card.Content extra className={'cardExtraContent'}>
                <Button
                  fluid
                  onClick={() => props.showVariables(retailer)}
                  buttonStyle={"secondary"}
                >
                  System Variables
                </Button>

                <CreditSolutionModal retailer={retailer} />

                <Button
                  fluid
                  buttonStyle={"skyblue"}
                  onClick={() => props.showRetailer(retailer)}
                >
                  See Details
                </Button>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </Grid>

      <div style={{ textAlign: "Center" }}>
        <Pagination
          defaultActivePage={1}
          totalPages={props.state.retailersTotal}
          onPageChange={(e, { activePage }) =>
            props.chageRetailerPage(activePage)
          }
        />
      </div>

      {props.state.variablesModal && (
        <VariablesModal
          {...props.state.retailer}
          hideModal={props.hideModal}
          updateRetailer={props.updateRetailer}
        />
      )}
    </div>
  );
};

export default Retailers;
