import React from "react";

import { Grid } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Section from "../../global/Section";
import StatisticCard from "./StatisticCard";

import { CustomDatePicker } from "../../helpers/general";
import BarChart from "../../utilities/barChart/BarChart";
import ListItem from "./ListItem";

import { ReactComponent as BuyersIcon } from "../../../images/users-icon.svg";
import { ReactComponent as TotalDealsIcon } from "../../../images/handshake-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../images/calendar-icon.svg";
import { ReactComponent as EmptyIcon } from "../../../images/inbox-empty.svg";

const Dashboard = (props) => {
  return (
    <div className={"body"}>
      <Grid stackable>
        <Grid.Column className={"graph"} width={11}>
          <Section>
            <h3 className={"sectionHeader"}>Statistics</h3>

            <div className={"statisticWrapper"}>
              <StatisticCard
                label={"Total deals"}
                value={props.state.quotes.total}
                icon={<TotalDealsIcon />}
                className={"totalDealsCard"}
                onClick={() => props.changeActiveItem("quotes")}
              />

              <StatisticCard
                label={"Buyers"}
                value={props.state.buyers.total}
                icon={<BuyersIcon />}
                className={"buyersCard"}
                onClick={() => props.changeActiveItem("buyers")}
              />

              <StatisticCard
                label={"Current week"}
                value={props.state.last_week}
                icon={<CalendarIcon />}
                className={"calendarCard"}
              />
            </div>

            <div className={"chartDates"}>
              <div className={"dateWrapper"}>
                <label>From: </label>

                <DatePicker
                  selected={props.state.chartDateStart}
                  onChange={(date) => props.onEditDate(date, "chartDateStart")}
                  customInput={<CustomDatePicker />}
                  popperPlacement={"auto-start"}
                />
              </div>

              <div className={"dateWrapper"}>
                <label>To: </label>

                <DatePicker
                  selected={props.state.chartDateEnd}
                  onChange={(date) => props.onEditDate(date, "chartDateEnd")}
                  customInput={<CustomDatePicker />}
                  popperPlacement={"auto-end"}
                />
              </div>
            </div>

            {props.state.chartData !== null &&
            props.state.chartData.labels.length !== 0 ? (
              <BarChart data={props.state.chartData} />
            ) : (
              <div className={"graphEmpty"}>
                <EmptyIcon />

                <h3>No information found for the specified period</h3>
              </div>
            )}
          </Section>
        </Grid.Column>

        <Grid.Column className={"infoList transparent"} width={5}>
          <Section>
            <h3 className={"sectionHeader"}>Best retailers</h3>

            <ul>
              {props.state.bestRetailers.map((retailer) => (
                <ListItem
                  key={retailer.id}
                  onClick={() => props.showRetailer(retailer)}
                >
                  <div className={"retailerInfoWrapper"}>
                    <span>{retailer.name}</span>

                    <span>Deals: {retailer.quots_count}</span>
                  </div>
                </ListItem>
              ))}
            </ul>
          </Section>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Dashboard;
