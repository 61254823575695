import React from "react";
import cn from "classnames";

import { Grid, Popup, Button, Icon, Header } from "semantic-ui-react";

import {
  FormatNumberToPrice,
  infoPaymentBenefit,
  infoWeeklyPayment,
  infoWeeklyPlusPayment,
  purchasingPowerInfo,
  returnInfoPayments,
} from "../../../../helpers/general";
import Buttons from "../Buttons";

const Payment = ({
  payment,
  onlyPayments,
  isSlidersFlow,
  money_owed,
  trade_in,
  ...props
}) => {
  let style = {
    color: payment === "monthly" ? "red" : "#00D367",
    fontWeight: payment == "monthly" ? "normal" : "bold",
  };

  return (
    <div className={"paymentColumnWrapper"}>
      <div style={{ textAlign: "center" }} className={"payment"}>
        {payment === "monthly" ? (
          <span className={"red"}>
            12 payments in 12 months
            <br />
            <small>(Greater Equity Risk)</small>
          </span>
        ) : payment === "weekly" ? (
          <span className={"green"}>
            Makes <strong className={"highlighted"}>13</strong> monthly payments
            In 12 months
          </span>
        ) : (
          <span className={"green"}>
            Makes <strong className={"highlighted"}>14</strong> monthly payments
            in 12 months
          </span>
        )}
      </div>

      <Grid
        className={
          !onlyPayments ? "paymentOptions paymentsBorder" : "paymentOptions"
        }
      >
        {!onlyPayments && (
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            style={{ padding: 0 }}
            className={"paymentAction staticInformation"}
          >
            <Button className="btn-text">
              <h3>
                <span
                  onClick={(e) =>
                    props.selectedPayment(
                      payment,
                      payment !== "monthly" ? "plan" : false,
                      props.car
                    )
                  }
                >
                  {payment !== "monthly" ? (
                    <span className="advantageGreen">
                      My Payment Power<span>TM</span>{" "}
                    </span>
                  ) : (
                    " Banker's Preferred "
                  )}
                  Repayment Plan
                </span>

                <Popup
                  wide={100}
                  content={returnInfoPayments(payment)}
                  trigger={
                    <Icon
                      link
                      name="info circle"
                      style={{ marginLeft: "10px" }}
                    />
                  }
                />
              </h3>
            </Button>
          </Grid.Column>
        )}

        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className={"headerColumn staticInformation"}
        >
          <Header
            as="h3"
            className="title-payments"
            style={{ textAlign: "center" }}
          >
            {payment == "monthly" ? "Monthly payment" : "Weekly"}

            {payment == "weeklyPlus" ? (
              <img
                className="icon-payments"
                src={require("../../../../../images/MyPayPow.png")}
              />
            ) : (
              payment == "weekly" && (
                <img
                  className="icon-payments"
                  src={require("../../../../../images/MyPayPow.png")}
                />
              )
            )}

            {payment == "weekly" ? (
              <Popup
                content={infoWeeklyPayment()}
                trigger={<Icon link name="info circle" />}
              />
            ) : payment == "weeklyPlus" ? (
              <Popup
                content={infoWeeklyPlusPayment()}
                trigger={<Icon link name="info circle" />}
              />
            ) : null}
          </Header>
        </Grid.Column>

        <Grid.Column className="record" mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">
            {payment == "monthly" ? "Monthly" : "Weekly"} payment{" "}
            {payment == "weeklyPlus" ? "+" : ""}
          </span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style}>
            <span className={cn(payment === "monthly" && "red")}>
              {FormatNumberToPrice(props.quots[payment].payment)}
            </span>

            {payment != "monthly" && (
              <Popup
                wide
                position={"left center"}
                content={
                  FormatNumberToPrice(props.quots[payment].payment) +
                  " = " +
                  FormatNumberToPrice(props.quots[payment].paymentNoFeeWeek) +
                  " (Loan)" +
                  " + " +
                  FormatNumberToPrice(props.quots[payment].debitFeeWeek) +
                  " (Program Fee)"
                }
                trigger={<Icon link name="info circle" />}
              />
            )}
          </span>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="border-bottom"></div>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Purchasing power</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style}>
            <span className={cn(payment === "monthly" && "red")}>
              {FormatNumberToPrice(props.quots[payment].purchasing_power)}
            </span>

            <span style={{ color: "#555555" }}>
              {payment !== "monthly" && (
                <Popup
                  wide
                  position={"left center"}
                  content={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purchasingPowerInfo(props.quots, payment),
                      }}
                    ></span>
                  }
                  trigger={<Icon link name="info circle" />}
                />
              )}
            </span>
          </span>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="border-bottom"></div>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Term Reduction</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style}>{props.quots[payment].term_reduction}</span>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Contracted Term</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style}>{props.quots[payment].contracted_term}</span>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="border-bottom"></div>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Effective Loan And Term</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style}>{props.quots[payment].effective_term}</span>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="border-bottom"></div>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">
            Payment Benefit Accelerated by
            {(payment == "weekly" || payment == "weeklyPlus") && (
              <Popup
                content={infoPaymentBenefit()}
                trigger={<Icon link name="info circle" />}
              />
            )}
          </span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style} className={cn(payment === "monthly" && "red")}>
            {FormatNumberToPrice(props.quots[payment].payment_benefit)}
          </span>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Interest Charges Reduced by</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style} className={cn(payment === "monthly" && "red")}>
            {FormatNumberToPrice(props.quots[payment].interest_charges)}
          </span>
        </Grid.Column>

        <Grid.Column mobile={10} tablet={9} computer={9}>
          <span className="color-blue-ligth">Late Payment Fees</span>
        </Grid.Column>

        <Grid.Column mobile={6} tablet={7} computer={7}>
          <span style={style} className={"lastRecord"}>
            {payment === "monthly" ? "Unprotected" : "MPP Protects Against"}
          </span>
        </Grid.Column>

        {!onlyPayments ? (
          <div className={"staticInformation"}>
            <Buttons
              selectedPayment={props.selectedPayment}
              payment={payment}
              car={props.car}
              isSlidersFlow={isSlidersFlow}
              preQualifiedForm={props.preQualifiedForm}
              openSession={props.openSession}
              retailer={props.retailer}
              money_owed={money_owed}
              finalQuots={props.quots}
              trade_in={trade_in}
              setQuotationState={props.setQuotationState}
            />
          </div>
        ) : (
          !props.quoteDetail &&
          props.currentUser.customer && (
            <Button
              className="btn-payment cursor-pointer"
              onClick={(e) => props.createQuot()}
            >
              <h3>Finalize</h3>
            </Button>
          )
        )}
      </Grid>
    </div>
  );
};

export default Payment;
