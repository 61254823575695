import React from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import {
  getAPPROVED,
  ScheduleText,
  DealerHelpText,
} from "../../../../helpers/general";
import Button from "../../../../global/Button";

import SimpleRegisterModalWrapper from "../../SimpleRegisterModalWrapper";

import "./Buttons.sass";

const Buttons = (props) => {
  const {
    car,
    popupPosition,
    isSlidersFlow,
    preQualifiedForm,
    openSession,
    retailer,
    money_owed,
    trade_in,
    finalQuots,
    setQuotationState,
  } = props;

  return (
    <Grid.Column mobile={16} tablet={16} computer={16}>
      {isSlidersFlow && (
        <SimpleRegisterModalWrapper
          form={preQualifiedForm}
          openSession={openSession}
          retailer={retailer}
          car={car}
          money_owed={money_owed}
          trade_in={trade_in}
          finalQuots={finalQuots}
          setQuotationState={setQuotationState}
        >
          <Button className={"slidersFlowButton buttonWrapper"}>
            <h3>Get Me Pre-Qualified</h3>

            <div className={"subtext"}>No impact On Credit Score</div>

            <div className={"subtext"}>No SSN or DOB Required</div>

            <div className={"subtext"}>No Purchase Required</div>
          </Button>
        </SimpleRegisterModalWrapper>
      )}

      <Button className="btn-payment-green buttonWrapper">
        <h3>
          <span
            onClick={(e) =>
              props.selectedPayment(
                props.payment,
                props.payment !== "monthly" ? "plan" : false,
                car
              )
            }
          >
            Approve Me For This Now
          </span>

          <Popup
            wide={80}
            content={getAPPROVED()}
            trigger={
              <Icon link name="info circle" style={{ marginLeft: "10px" }} />
            }
            position={popupPosition}
          />
        </h3>
      </Button>

      <Button className="btn-payment-default buttonWrapper">
        <Grid verticalAlign="center" className={"buttonContent"}>
          <Grid.Column
            onClick={(e) =>
              props.selectedPayment(props.payment, "dealerHelp", car)
            }
            mobile={15}
            tablet={15}
            computer={15}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "0.75rem",
              justifyContent: "space-around",
            }}
          >
            <img
              className="img-1"
              src={require("../../../../../images/ico-dealer-blue.png")}
              alt=""
            />
            <img
              className="img-2"
              src={require("../../../../../images/ico-dealer-white.png")}
              alt=""
            />
            <h4>
              <p className={"tradeMark"}>
                Dealer Help Live <sup>TM</sup>
              </p>

              <p>Connect Me Now</p>
              <span className={"capture"}>
                No personal contact info will be shared
              </span>
            </h4>
          </Grid.Column>

          <Grid.Column
            mobile={2}
            tablet={2}
            computer={2}
            textAlign="center"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3>
              <Popup
                wide={80}
                content={DealerHelpText()}
                trigger={<Icon link name="info circle" />}
                position={popupPosition}
              />
            </h3>
          </Grid.Column>
        </Grid>
      </Button>

      <Button className="btn-payment buttonWrapper">
        <Grid verticalAlign="center" className={"buttonContent"}>
          <Grid.Column
            onClick={(e) =>
              props.selectedPayment(props.payment, "schedule", car)
            }
            mobile={14}
            tablet={14}
            computer={14}
            textAlign="left"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              justifyContent: "space-around",
            }}
          >
            <img
              className="img-1"
              src={require("../../../../../images/ico-calendar-white.png")}
              alt=""
            />
            <img
              className="img-2"
              src={require("../../../../../images/ico-calendar-gray.png")}
              alt=""
            />
            <h4>
              <p className="nomargin">Schedule Live Video Call For Later</p>
              <span className={"capture"}>
                No personal contact info will be shared
              </span>
            </h4>
          </Grid.Column>

          <Grid.Column
            mobile={2}
            tablet={2}
            computer={2}
            textAlign="center"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3>
              <Popup
                wide={80}
                content={ScheduleText()}
                trigger={<Icon link name="info circle" />}
                position={popupPosition}
              />
            </h3>
          </Grid.Column>
        </Grid>
      </Button>
    </Grid.Column>
  );
};

export default Buttons;
