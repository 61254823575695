import React from "react";

import { Grid, Table } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import BarChart from "../../utilities/barChart/BarChart";
import StatisticCard from "../../admin/Dashboard/StatisticCard";
import ListItem from "../../admin/Dashboard/ListItem";
import Section from "../../global/Section";

import { ReactComponent as BuyersIcon } from "../../../images/users-icon.svg";
import { ReactComponent as TotalDealsIcon } from "../../../images/handshake-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../images/calendar-icon.svg";
import { ReactComponent as EmptyIcon } from "../../../images/inbox-empty.svg";

import { CustomDatePicker } from "../../helpers/general";

const Dashboard = (props) => {
  return (
    <div className={"body"}>
      <Grid stackable>
        <Grid.Column className={"transparent"} width={11}>
          <Section>
            <h3 className={"sectionHeader"}>Statistics</h3>

            <div className={"statisticWrapper"}>
              <StatisticCard
                label={"Total deals"}
                value={props.state.retailer.quots_count}
                icon={<TotalDealsIcon />}
                className={"totalDealsCard"}
                onClick={() => props.changeActiveItem("quotes")}
              />

              <StatisticCard
                label={"Buyers"}
                value={props.state.buyers.total}
                icon={<BuyersIcon />}
                className={"buyersCard"}
                onClick={() => props.changeActiveItem("buyers")}
              />

              <StatisticCard
                label={"Current week"}
                value={props.state.retailer.last_week}
                icon={<CalendarIcon />}
                className={"calendarCard"}
              />
            </div>

            <div className={"chartDates"}>
              <div className={"dateWrapper"}>
                <label>From: </label>

                <DatePicker
                  selected={props.state.chartDateStart}
                  onChange={(date) => props.onEditDate(date, "chartDateStart")}
                  customInput={<CustomDatePicker />}
                  popperPlacement={"auto-start"}
                />
              </div>

              <div className={"dateWrapper"}>
                <label>To: </label>

                <DatePicker
                  selected={props.state.chartDateEnd}
                  onChange={(date) => props.onEditDate(date, "chartDateEnd")}
                  customInput={<CustomDatePicker />}
                  popperPlacement={"auto-end"}
                />
              </div>
            </div>

            {props.state.chartData !== null &&
            props.state.chartData.labels.length !== 0 ? (
              <BarChart data={props.state.chartData} />
            ) : (
              <div className={"graphEmpty"}>
                <EmptyIcon />

                <h3>No information found for the specified period</h3>
              </div>
            )}
          </Section>
        </Grid.Column>

        <Grid.Column className={"infoList"} width={5}>
          <Section>
            <h3 className={"sectionHeader"}>Last deals</h3>

            <ul>
              {props.state.lastQuotes.map(({ id, car }) => (
                <ListItem key={id} onClick={() => props.showDetails(id)}>
                  <div className={"retailerInfoWrapper"}>
                    <span>{`${car.make} ${car.model}`}</span>
                  </div>
                </ListItem>
              ))}
            </ul>
          </Section>
          {/* <Table className={"customTable last-deals"}>
            <Table.Header>
              <Table.Row className={"upperHeader"}>
                <Table.HeaderCell colSpan={2}>LAST DEALS</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.state.lastQuotes.map(({ id, car }, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{`${car.make} ${car.model}`}</Table.Cell>

                  <Table.Cell textAlign={"center"}>
                    <span
                      className={"detailsQuote"}
                      onClick={() => props.showDetails(id)}
                    >
                      See more...
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table> */}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Dashboard;
