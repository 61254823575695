import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Icon, Loader, Popup } from 'semantic-ui-react';
import { paymentDate } from '../../helpers/general';
import ModalComponent from '../alertModal/modal';
import { postPaymentPlan, putPaymentPlan } from '../requests';
import { Documents } from './documents/Documents';

export const EditButton = (props) => {
  const { form, quote, retailer, customer, userType, disabled, amortization, status, errors, payments, fee } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [openDocs, setOpenDocs] = useState(false);
  const [extraData, setExtraData] = useState({ firstDebit: null, lastWeekly: null, firstMonthly: null })

  // ------------------------------------- send data to backend --------
  const createPaymentPlan = () => {
    let auxPayDay = paymentDate(form);
    let params = {
      quote_id: quote?.id,
      retailer_id: retailer?.id,
      customer_id: customer.id,
      ...form,
      paymentDate: auxPayDay[0].date === undefined && status === 1 ? null : moment(auxPayDay[0].date).format('YYYY-MM-DD'),
      amortization: amortization,
      endDate: moment(form.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      firstDate: moment(form.firstDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      startDate: moment(form.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      status,
      payments,
    }
    const responseSuccess = (response) => {
      setIsLoading(false);
      setModal({ status: 201, message: userType === 'retailer' ? "Loan details were updated" : "Payment plan was " + (status ? 'started' : 'updated'), response: response });
    }
    const responseError = (error) => {
      setIsLoading(false);
      console.log(error, error.response.status);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, try again later" });
        }
      }
    }
    setIsLoading(true);
    if (form.id) {
      putPaymentPlan(params).then(response => {
        responseSuccess(response);
      }).catch(error => {
        responseError(error);
      });
    } else {
      postPaymentPlan(params).then(response => {
        responseSuccess(response);
      }).catch(error => {
        responseError(error);
      });
    }
  }

  // ---------------------- show documents
  const openDocuments = () => {
    let auxExtraData = { ...extraData };
    for (const item of payments) {
      if (item.type === 'monthly') {
        auxExtraData.firstMonthly = moment(item.date).format('MM-DD-YYYY');
        break;
      }
    }
    for (const item of payments) {
      if (item.type === 'weekly') {
        auxExtraData.firstDebit = moment(item.date).format('MM-DD-YYYY');
        break;
      }
    }
    let auxPayments = [...payments].reverse();
    for (const item of auxPayments) {
      if (item.type === 'weekly') {
        auxExtraData.lastWeekly = moment(item.date).format('MM-DD-YYYY');
        break;
      }
    }
    setExtraData(auxExtraData);
    setOpenDocs(true);
  }

  // ------------------- close Documents
  const closeDocuments = (flag) => {
    setOpenDocs(false);
    if (flag) {
      createPaymentPlan();
    }
  }

  // ------------------------------------ close alert modal --------------------
  const closeAlertModal = () => {
    if (modal.status === 201) {
      let auxForm = { ...form };
      let flag = false;
      if (userType === 'customer') {
        auxForm.status = status;
        auxForm.created_at = modal.response.data.created_at;
        flag = status === 1 ? true : false;
      }
      auxForm.amortization = modal.response.data.amortization;
      auxForm.id = modal.response.data.id;
      props.updatePlan(auxForm, flag);
    }
    setModal({});
  }

  useEffect(() => {
    if (userType === 'customer' && status === 0) {
      setSaveDisabled(true);
      if (form.loanMonthlyPayment && form.loanTerm && form.startDate && form.firstDate && !errors.loanMonthlyPayment
        && !errors.loanTerm && !errors.startDate && !errors.firstDate) {
        setSaveDisabled(false);
      }
    }
  }, [props])


  return (
    <div style={{ display: "inline-block" }}>
      {
        userType === 'customer' && status === 0 ?
          <Popup
            trigger={
              <div>
                <Button primary onClick={createPaymentPlan} disabled={saveDisabled}>
                  Save
                </Button>
              </div>
            }
            content="To save the plan you must complete the following fields: Loan Monthly, Loan Start Date, First Payment Date and Loan Term."
            position='top center'
          />
          :
          <Popup
            trigger={
              <div>
                <Button color={status === 1 ? 'green' : 'blue'} onClick={userType === 'customer' && status === 1 ? openDocuments : createPaymentPlan} disabled={disabled}>
                  {userType === 'customer' ? 'Start' : 'Edit Loan Details'}
                </Button>
              </div>
            }
            content="To start the plan you must complete all the required fields and to get an Installment value in the table"
            position='top center'
          />

      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={closeAlertModal} />
      }
      <Documents
        open={openDocs} plan={form} customer={customer.customer} payments={payments}
        amortization={amortization} fee={fee} quote={quote} retailer={retailer} data={extraData} closeDocuments={closeDocuments}
      />
    </div>
  );
};