import React from "react";

import { Modal, Button } from "semantic-ui-react";

const ConfirmModal = (props) => {
  return (
    <Modal
      size="mini"
      className="buyer-modal"
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <h2 className="title-info">
          {props.state.confirmModal == 2
            ? "Accept deal"
            : props.state.confirmModal == 3
            ? "Cancel deal"
            : props.state.confirmModal == "formula"
            ? "Interest percentages"
            : null}
        </h2>

        <h3>
          {props.state.confirmModal == 2 ? (
            "Are you sure you accept the deal?"
          ) : props.state.confirmModal == 3 ? (
            "Are you sure to cancel the deal?"
          ) : props.state.confirmModal == "formula" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.state.changedValues,
              }}
            ></div>
          ) : null}
        </h3>
      </Modal.Content>

      <Modal.Actions>
        <Button
          className="btn-continue"
          onClick={() => props.setState({ confirmModal: false })}
        >
          <h3>Cancel</h3>
        </Button>

        {props.state.confirmModal == 2 || props.state.confirmModal == 3 ? (
          <Button
            className="btn-continue"
            onClick={() => props.confirmQuotStatus()}
          >
            <h3>Accept</h3>
          </Button>
        ) : props.state.confirmModal == "formula" ? (
          <Button className="btn-continue" onClick={() => props.updateFormula()}>
            <h3>Accept</h3>
          </Button>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
