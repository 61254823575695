import moment from "moment";
import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { Footer } from './Footer';

export const Page2 = ({ retailer, fee }) => {
  return (
    <div className='page-2' id="page3">
      <img src={require("../../../../../images/MyPayPow.png")} style={{ width: "250px" }} />
      <Header as="h2" className='title'>Schedule A - Consumer Pricing and Referral Fees</Header>
      <Header as="h3" textAlign='center'>Consumer Pricing Schedule</Header>
      <Grid columns={2} stackable>
        <Grid.Column width={6}>
          <span className='under-line'>My Payment Power&trade; Enrollment Fees:</span>
        </Grid.Column>
        <Grid.Column width={10}>
          <span className='d-block'>Finance: Flat Enrollment Fee of $399.00</span>
          <span className='d-block'>Lease: Flat Enrollment Fee of $199.00</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={6}>
          <span className='under-line'>One-time Account Verification Fee:</span>
        </Grid.Column>
        <Grid.Column width={10}>
          <span>$2.45</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={6}>
          <span className='under-line'>Recurring ACH Fees:</span>
        </Grid.Column>
        <Grid.Column width={10}>
          <span>Biweekly Consumer ACH Fee of $2.45 Weekly Consumer ACH Fee of$1.25.</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable style={{ marginTop: "5rem" }}>
        <Grid.Column width={4}>
          <span className='under-line'>Dealership:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span>$ of Referral Fee paid when fully collected. *</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <span className='under-line'>Finance Manager:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span>$ of Referral Fee paid when fully collected. *</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <span className='under-line'>Other:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span>$ of Referral Fee paid when fully collected. *</span>
        </Grid.Column>
      </Grid>
      <p></p>
      <p>
        *Unless otherwise specified, referral fees are paid in their entirety, not in partial payments. Lease payouts are 50% less than
        finance due to the reduced enrollment fee. There are no chargebacks of paid referral fees.
      </p>
      <Grid columns={2} stackable style={{ marginTop: "5rem" }}>
        <Grid.Column width={4}>
          <span>Dealership Name:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <span>City, State:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span className='under-line'>{retailer?.city && retailer?.state ? retailer?.city + " " + retailer?.state : null}</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={5}>
          <span>Authorized Signers Name (Printed):</span>
        </Grid.Column>
        <Grid.Column width={11}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <span>Authorized Signers Title:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
      </Grid>
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <span>Authorized Signers Title:</span>
        </Grid.Column>
        <Grid.Column width={12}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
      </Grid>
      <Grid columns={3} stackable>
        <Grid.Column width={4}>
          <span>Dealer Authorized Signature:</span>
        </Grid.Column>
        <Grid.Column width={8}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
        <Grid.Column width={4}>
          <span>Date: <span className='under-line'>{retailer ? moment().format('MM-DD-YYYY') : null}</span></span>
        </Grid.Column>
      </Grid>
      <Grid columns={3} stackable>
        <Grid.Column width={4}>
          <span>USEA Authorized Signature:</span>
        </Grid.Column>
        <Grid.Column width={8}>
          <span className='under-line'>{retailer?.name}</span>
        </Grid.Column>
        <Grid.Column width={4}>
          <span>Date: <span className='under-line'>{retailer ? moment().format('MM-DD-YYYY') : null}</span></span>
        </Grid.Column>
      </Grid>
      <Footer page={2} />
    </div>
  );
};