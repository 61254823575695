import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Dimmer,
  Grid,
  Header,
  Loader,
  Message,
  Pagination,
  Statistic,
  Table,
} from "semantic-ui-react";
import { FormatNumberToPrice } from "../../helpers/general";
import { LoanDetails } from "../loanDetails/LoanDetails";
import { getPaymentPlans } from "../requests";
import { Events } from "./events/Events";
import Button from "../../global/Button";

import { PaymentPlansFilter } from "./PaymentPlansFilter";
import { Payments } from "./payments/Payments";

import "./PaymentPlans.sass";

const filter = { option: "", status: "", id: "" };

export const PaymentPlans = ({ customer, type }) => {
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState({
    data: [],
    last_page: 0,
    total: 0,
  });
  const [resume, setResume] = useState({ plans: 0, total: 0, balance: 0 });
  const [page, setPage] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState(filter);
  const [mounted, setmounted] = useState(false);

  const getPaymentPlansList = (
    page = 1,
    filter = defaultFilter,
    isMounted = true
  ) => {
    setDefaultFilter(filter);
    setIsLoading(true);
    const params = {
      page,
      status: filter.status,
      ...(filter.option !== "range"
        ? { date: filter.option }
        : filter.startDate &&
          filter.startDate && {
            startDate: moment(filter.startDate, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            ),
            endDate: moment(filter.endDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
          }),
      ...(filter.id && { searchId: filter.id }),
      type,
      id: type === "admin" ? customer.id : null,
    };
    getPaymentPlans(params)
      .then((response) => {
        setIsLoading(false);
        if (isMounted) {
          setPaymentPlans(response.data.paymentPlans);
          let auxResume = { ...resume };
          auxResume.total = response.data.resume.total;
          auxResume.balance = response.data.resume.balance;
          auxResume.plans = response.data.paymentPlans.total;
          setResume({ ...auxResume });
          setPage(page);
        }
        setmounted(true);
        setPage(page);
      })
      .catch((error) => {
        setmounted(true);
        setIsLoading(false);
        if (error.response) {
          setModal({
            status: 500,
            message:
              "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support.",
          });
        }
      });
  };

  // ------------------------------------- update plan after update in detail
  const updatePaymentPlan = (paymentPlan, creation) => {
    let auxPaymentPlans = { ...paymentPlans };
    let list = [...auxPaymentPlans.data];
    if (creation) {
      if (page === 1) {
        list.unshift(paymentPlan);
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === paymentPlan.id) {
          list[i] = { ...list[i], ...paymentPlan };
          break;
        }
      }
    }
    auxPaymentPlans.data = list;
    setPaymentPlans(auxPaymentPlans);
  };

  useEffect(() => {
    let isMounted = true;
    if (!mounted) {
      getPaymentPlansList(1, filter, isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="payment-plans">
      <Header size="huge" className={"header"}>
        Payment Plans
      </Header>

      {modal.message ? (
        <Message error header="Error" content={modal.message} />
      ) : (
        <div>
          <Grid className={"generalInfo transparent plansStadistics"}>
            <div
              className={"statisticCard"}
              style={{ backgroundColor: "#003f69" }}
            >
              <Statistic>
                <Statistic.Label>PLANS</Statistic.Label>
                <Statistic.Value>{resume.plans}</Statistic.Value>
              </Statistic>
            </div>
            <div
              className={"statisticCard"}
              style={{ backgroundColor: "#2295e2" }}
            >
              <Statistic>
                <Statistic.Label>BALANCE</Statistic.Label>
                <Statistic.Value>
                  {FormatNumberToPrice(resume.balance)}
                </Statistic.Value>
              </Statistic>
            </div>
            <div
              className={"statisticCard"}
              style={{ backgroundColor: "#9ad6ff" }}
            >
              <Statistic>
                <Statistic.Label>TOTAL PAID</Statistic.Label>
                <Statistic.Value>
                  {FormatNumberToPrice(resume.total)}
                </Statistic.Value>
              </Statistic>
            </div>
            {type === "customer" && customer.customer.account?.id && (
              <Statistic>
                <LoanDetails
                  customer={customer}
                  type={"customer"}
                  create={true}
                  updatePaymentPlan={updatePaymentPlan}
                />
              </Statistic>
            )}
          </Grid>

          <PaymentPlansFilter filter={(form) => getPaymentPlansList(1, form)} />

          {paymentPlans.data.length > 0 ? (
            <div className="table-wrapper">
              <Table className={"customTable"} celled unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                    <Table.HeaderCell>Total amount</Table.HeaderCell>
                    <Table.HeaderCell>Starting Principal</Table.HeaderCell>
                    <Table.HeaderCell>Payment</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Creation Date</Table.HeaderCell>
                    <Table.HeaderCell>Weekly Payments</Table.HeaderCell>
                    <Table.HeaderCell>Total paid</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    {type === "admin" && <Table.HeaderCell></Table.HeaderCell>}
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {paymentPlans.data.map((item) => (
                    <Table.Row>
                      <Table.Cell textAlign="center">{item.id}</Table.Cell>
                      <Table.Cell>
                        {FormatNumberToPrice(
                          item?.amortization?.total?.sum_of_installments
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {FormatNumberToPrice(item.principal)}
                      </Table.Cell>
                      <Table.Cell>
                        {FormatNumberToPrice(item.payment)}
                      </Table.Cell>
                      <Table.Cell>
                        {item.status === 0 ? (
                          <Button className="plan-status" buttonStyle={"grey"}>
                            Not completed
                          </Button>
                        ) : item.status === 1 ? (
                          <Button className="plan-status" buttonStyle={"green"}>
                            Activated
                          </Button>
                        ) : item.status === 2 ? (
                          <Button className="plan-status" buttonStyle={"green"}>
                            Finished
                          </Button>
                        ) : item.status === 3 ? (
                          <Button
                            className="plan-status"
                            buttonStyle={"danger"}
                          >
                            Stopped
                          </Button>
                        ) : (
                          <Button
                            className="plan-status"
                            buttonStyle={"danger"}
                          >
                            Cancelled
                          </Button>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(item.created_at).format("LLL")}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.weekly_payments_count || 0}
                      </Table.Cell>
                      <Table.Cell>
                        {FormatNumberToPrice(
                          item.paid_sum != null ? item.paid_sum : 0
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Payments
                          id={item.id}
                          customer={customer}
                          type={type}
                        />
                      </Table.Cell>
                      {type === "admin" && (
                        <Table.Cell>
                          <Events type={"plan"} id={item.id} />
                        </Table.Cell>
                      )}
                      <Table.Cell>
                        {type === "customer" ? (
                          <LoanDetails
                            id={item.id}
                            quote={item.quot}
                            customer={customer}
                            type={"customer"}
                            detail={true}
                            retailer={item.retailer}
                            updatePaymentPlan={updatePaymentPlan}
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <Message style={{ textAlign: "center", margin: "2em 0" }}>
              <Message.Header style={{ textAlign: "center" }}>
                There are not payment plans
              </Message.Header>
            </Message>
          )}
          {paymentPlans.data.length > 0 && (
            <div style={{ textAlign: "center", margin: "1em 0" }}>
              <Pagination
                defaultActivePage={1}
                totalPages={paymentPlans.last_page}
                onPageChange={(e, { activePage }) =>
                  getPaymentPlansList(activePage, true)
                }
              />
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};
