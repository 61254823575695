import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { AddRetailer } from "../retailerDetails/AddRetailer";

import Button from "../../global/Button";

import "./useHeader.sass";

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, admin } = this.props;

    return (
      <div className="headerContainer">
        <div className={"dashHeader"}>
          <Grid>
            <Grid.Column
              className={"title"}
              mobile={16}
              tablet={8}
              computer={8}
            >
              <Button
                icon="bars"
                className="menu-open"
                onClick={this.props.openMenu}
              />
              <Header as={"h2"}>
                Dashboard
                <Header.Subheader>
                  Welcome back, <span>{name}</span>
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column
              className={"ui-computer"}
              mobile={16}
              tablet={8}
              computer={8}
            >
              <div className={"rightHeader"}>
                {admin ? (
                  <AddRetailer addNewRetailer={this.props.addNewRetailer} />
                ) : (
                  <div className={"addRetailer ui-computer"}></div>
                )}
                <div className={"profileName ui-computer"}>
                  <span>{name}</span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

export default UserHeader;
