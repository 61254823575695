import React from "react";
import cn from "classnames";

import { DateInput as SemanticDateInput } from "semantic-ui-calendar-react";

import "./styles.sass";

const DateInput = ({ className, ...rest }) => {
  return (
    <div className={cn("dateInputWrapper", className)}>
      <SemanticDateInput {...rest} />
    </div>
  );
};

export default DateInput;
