import React from 'react';
import { Header } from 'semantic-ui-react';

export const Text2 = () => {
  return (
    <div>
      <p>
        After debiting your account and prior to disbursement, funds will be held in a master bank account created by
        USEA for your benefit as your
        agent. The funds will be comingled with funds of other USEA customers; however, you are deemed the principal
        with respect to the funds in your subaccount. USEA will track the funds in each of its customers' subaccounts each day; however, it will not necessarily maintain and update
        subaccounts over the course of a day and, accordingly, account figures provided over the course of a day are provisional. This arrangement does
        not create a trust or other fiduciary obligations on the part of USEA.
      </p>
      <p>
        USEA reserves the right to suspend or cancel any payments if your Deposit Account is not in good standing. USEA will credit back to the
        Deposit Account any debits made to fund cancelled payments; provided, however, you authorize USEA, in its sole discretion, to withhold and
        collect from such amounts any service fees or other amounts owed under this Agreement.
      </p>
      <p>
        (F) If you designate yourself or any other person as a "Payor," you thereby represent and warrant that each such Payor is authorized to initiate
        debit or credit entries, as applicable, to the Deposit Account.
      </p>
      <p>
        (G) You hereby authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity and/or
        authenticate your identity and account information and, for business accounts, your company or employer. This may include asking you for
        further information and/or documentation about your account usage or identity, or requiring you to take steps to confirm ownership of your email
        address, wireless/cellular telephone number or financial instruments, and verifying your information against third party databases or through other
        sources. This process is for internal verification purposes.
      </p>
      <Header as="h3" >
        <span className='underline bold'>Service Fees and Payment.</span> You shall pay all fees and charges listed on this Agreement applicable to the Service, including, but not limited to:
      </Header>
      <ol>
        <li>$399.00 Enrollment, Account Verification fee of $2.45, ACH fee of $1.25/weekly debit, $2.45/biweekly, bimonthly or monthly debit;</li>
        <li>NSF ($29.50), Revoked Debit ($50), Stop Payment ($25) fees, Customer Advises Not Authorized ($50);</li>
        <li>FedEx Request ($35), Rush Check Request ($10), Phone Payment ($10), Paper Statements Mailed ($5);</li>
        <li>Inactivity Fees of $7.50 per month, Escheatment Fee (Unclaimed Account) of $50; and</li>
        <li>Any additional fees that you may contract for or incur with USEA.</li>
      </ol>
      <p>
        We reserve the right to change the amount of any fees from time to time, in our sole discretion. We will post the revised fees on the
        www.usequityadvantage.com website or notify you by email in advance of the effective date of such changes. If the change in fees is not
        acceptable, your sole and exclusive remedy shall be to stop using the Service. By continuing to use the Service after the fee increase, you accept
        all changes in service fees. All Service fees are non-refundable. Prior to charging the inactivity fee USEA will make all attempts to contact you
        and you will have an additional 90 days after a “Notice of Inactivity Fee” letter is sent to the most recent address we have on record for you.
        These additional fees can impact your savings. You may be charged a fee if you do not accept electronic communication. USEA reserves the right
        to cancel your account in the event of non-payment of fees.
      </p>
      <p>
        <span className='underline bold'>Information and Customer Requests.</span>It is your responsibility to provide any changes to your bank or lender information to USEA in a timely
        manner to ensure that your payments are processed properly. USEA requires notice of any changes to your banking or loan accounts within the
        following time frames: (1) Requests for changes to your debit schedule must be made a minimum of five (5) business days prior to the next
        scheduled debit date. (2) Changes to your bank information that may affect processing your payment, including but not limited to account
        number and/or bank address must be made a minimum of five (5) business days prior to the next scheduled debit date; (3) Changes to your loan
        information necessary for payment to a Payee, including but not limited to your lender and/or account number, must be made a minimum of
        ten (10) business days prior to your payment due date if the new information is to be applied to an upcoming payment. Business days do not
        include weekends or Federal Reserve holidays. All notices required or permitted hereunder shall be in writing and may be delivered via U.S.
        Mail, courier services, overnight postal carrier to US Equity Advantage, 800 N. Magnolia Avenue, Suite 1275, Orlando, FL 32803 or via
        email at customercare@autopayplus.com or to such other address as USEA may designate. Such notices shall be deemed delivered when
        personally received or five (5) days after mailing with adequate postage. If you choose to opt out of receiving USEA promotional email
        please visit the My Payment Power website or you can send your request to Customer Care at 800 North Magnolia Ave., Suite 1275, Orlando FL
        32803. This does not preclude USEA from emailing you regarding your account.
      </p>
      <p>
        You acknowledge that you will not receive written notice from USEA of the amount to be debited or the due dates of the debiting after receiving
        notification of your first debit, however, you may call our toll-free number (800) 894-5000 at any time during regular business hours of 9:00 am
        to 6:00 pm EST Mon. - Fri. to receive updated information regarding your most recent debit. Several states require that receipts be made
        available to you. To access this information log onto your Customer portal on the www.autopayplus.com website to access receipt information.
      </p>
      <p>
        <span className='underline bold'>Estimated Savings Using My Payment Power&trade;</span> You acknowledge that all calculations made by USEA, or any representing agent(s) are based on
        information supplied by you and you accept sole responsibility for the accuracy of any of this information. You acknowledge numerous factors
        could have a significant effect on any potential savings and payment schedules, including but not limited to scheduling and payment changes you
        request during your use of the Service, interest rate, term, amount financed and rate of depreciation. The sole representation made by USEA is
        strictly limited to “a potential of interest savings and an accelerated equity position in the item collateralized by your loan after taking into
        account all associated fees.”
      </p>
      <p>
        <span className='underline bold'>Our Commitment to Privacy.</span> At USEA, we are committed to protecting consumer information and their privacy. To guard your information,
        USEA has put in place appropriate physical, electronic and managerial procedures to safeguard and secure the information in accordance to
        Federal standards. A Privacy Notice is mailed out to you upon enrollment into the My Payment Power Program and you acknowledge that annual
        updates are available for view on the My Payment Power website at www.autopayplus.com under the Privacy Notice.
      </p>
    </div>
  );
};