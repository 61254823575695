import React from "react";

import { Form, Header } from "semantic-ui-react";

import Input from "../../../global/Input";

const DownPayment = (props) => {
  return (
    <>
      <Header as={"h3"}>Down Payment</Header>

      <Form.Group>
        <Form.Field width={5}>
          <label>Minimum</label>

          {props.state.flagEdit ? (
            <span>{props.state.min_down_payment}%</span>
          ) : (
            <div>
              <Input
                value={props.state.min_down_payment}
                placeholder={"Minimum"}
                onChange={(e) =>
                  props.onEditInput("min_down_payment", e.target.value)
                }
                label={{ basic: true, content: "%" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.min_down_payment.length > 0 &&
                  props.state.msnErrors.min_down_payment
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={5}>
          <label>Maximum</label>

          {props.state.flagEdit ? (
            <span>{props.state.max_down_payment}%</span>
          ) : (
            <div>
              <Input
                value={props.state.max_down_payment}
                placeholder={"Maximum"}
                onChange={(e) =>
                  props.onEditInput("max_down_payment", e.target.value)
                }
                label={{ basic: true, content: "%" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.max_down_payment.length > 0 &&
                  props.state.msnErrors.max_down_payment
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={6}>
          <label>
            Default <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.default_down_payment}%</span>
          ) : (
            <div>
              <Input
                value={props.state.default_down_payment}
                placeholder={"Maximum"}
                onChange={(e) =>
                  props.onEditInput("default_down_payment", e.target.value)
                }
                label={{ basic: true, content: "%" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.default_down_payment.length > 0 &&
                  props.state.msnErrors.default_down_payment
                }
              />
            </div>
          )}
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default DownPayment;
