import React, { useEffect, useState } from "react";
import {
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";
import {
  validEmail,
  validPhoneNumber,
  validCodeNumber,
} from "../../helpers/general";
import ModalComponent from "../../utilities/alertModal/modal";
import CodeModal from "../../utilities/codeModal/codeModal";
import { UserInformation } from "../../utilities/contact/UserInformation";
import { signUp } from "../../utilities/requests";
import { TermsConditions } from "../../utilities/termsConditions/TermsConditions";
import Login from "../login/login";
import Button from "../../global/Button";

import "./SimpleRegister.sass";

const defaultForm = {
  email: "",
  phone_number: "",
  password: "",
  confirmPassword: "",
  agreement: "false",
};

export const SimpleRegister = (props) => {
  const { logged, disabled, data, openSession, retailer, isFooterTextHidden } =
    props;

  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState(defaultForm);
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [modal, setModal] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validCode, setvalidCode] = useState(false);
  const [user, setUser] = useState({});

  // ---------------------------- update form and validate -----------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setForm({ ...auxForm });
    setErrors({ ...auxErrors });
    validation(auxForm, auxErrors);
  };

  const validation = (auxForm, auxErrors) => {
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors, auxForm);
      }
    }
    setDisabledForm(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (
          (key !== "agreement" && element !== "") ||
          (key === "agreement" && (!element || element === "false"))
        ) {
          setDisabledForm(true);
        }
      }
    }
  };

  const validateForm = (value, label, errors, auxForm = form) => {
    switch (label) {
      case "email":
        errors.email = validEmail(value) ? "" : "Email is not valid";
        break;
      case "phone_number":
        errors.phone_number = !validPhoneNumber(value)
          ? "Phone number must be 10 digits"
          : !validCodeNumber(value)
          ? "Invalid phone number"
          : "";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Password must be at least 8 characters long" : "";
        errors.confirmPassword = "Passwords do not match";
        if (auxForm.confirmPassword === value) {
          errors.confirmPassword = "";
        }
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value !== auxForm.password ? "Passwords do not match" : "";
        break;
      case "agreement":
        errors.agreement = value;
        break;
      default:
        break;
    }
    return errors;
  };

  const handleClick = () => {
    if (logged) {
      props.CreditSolutionRequest();
    } else {
      setOpen(true);
    }
  };

  const signUpButton = () => {
    if (isLoading) return;
    let auxForm = { ...form, ...data };
    auxForm["password_confirmation"] = auxForm.confirmPassword;
    delete auxForm.confirmPassword;
    if (validCode) {
      auxForm = { id: user.id, code: form.code };
    }
    auxForm["retailerId"] = retailer.id;
    setIsLoading(true);
    setDisabledForm(true);
    signUp(auxForm)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 202) {
          setvalidCode(true);
          setUser(response.data);
        } else {
          setvalidCode(false);
          props.CreditSolutionRequest(response.data);
          localStorage.setItem("currentUser", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDisabledForm(false);
        if (error.response) {
          if (error.response.status === 422) {
            setError({
              message: "Sorry. Fields are missing to complete the registration",
            });
          } else if (error.response.status === 406) {
            setError({ message: error.response.data.message });
          } else if (error.response.status === 400) {
            setModal({ message: "The code is invalid", status: 400 });
          } else {
            setError({
              message: "Sorry. An error occurred, please try again later",
            });
          }
        }
      });
  };

  useEffect(() => {
    setRegister(openSession);
    if (openSession) {
      setOpen(true);
    }
  }, [props]);

  return (
    <div>
      {!props.trigger && (
        <Container textAlign="center" className={"buttonContainer"}>
          <div className="getPreQualifiedWrapper">
            <Button
              className="btn-continue"
              onClick={handleClick}
              disabled={disabled}
            >
              Get Pre-Qualified
            </Button>
          </div>

          {!isFooterTextHidden && (
            <p className="m-t-3">
              <span className="d-block">No impact on your credit score.</span>
              <span className="d-block">No SSN OR DOB required.</span>
              <span className="d-block">No obligation to buy!</span>
            </p>
          )}
        </Container>
      )}

      <Modal
        className="simple-register"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
        trigger={props.trigger}
      >
        <Modal.Content scrolling>
          <div className="radio-form">
            <Form>
              <Form.Group>
                <Form.Field
                  label={
                    <Header>
                      <p>Save my Vehicle & build my Vault</p>{" "}
                      <small>
                        (Avoids need for future Vehicle Vault login)
                      </small>
                    </Header>
                  }
                  value={true}
                  control="input"
                  type="radio"
                  onChange={() => setRegister(true)}
                  name="register"
                  checked={register}
                  className="radio-input"
                  width={16}
                />
                <Form.Field
                  label={
                    <Header>
                      <p>Skip saving & keep shopping prequalified</p>
                    </Header>
                  }
                  value={false}
                  control="input"
                  type="radio"
                  checked={!register}
                  onChange={() => setRegister(false)}
                  name="register"
                  className="radio-input"
                  width={16}
                />
              </Form.Group>
            </Form>
          </div>

          {register && (
            <Form>
              <Divider />

              {!login ? (
                <div>
                  <h2 className="upperTitle" style={{ cursor: "default" }}>
                    Sign Up
                  </h2>

                  {error.message && (
                    <Message
                      error
                      visible
                      header="Login Failed"
                      content={error.message}
                    />
                  )}

                  <UserInformation
                    form={form}
                    register={true}
                    msnErrors={errors}
                    onEditInput={onEditInput}
                  />
                  <Form.Group>
                    <Form.Checkbox
                      className="agree-check"
                      id={"agreement"}
                      value={
                        form.agreement === "false" ? false : form.agreement
                      }
                      label={
                        <label>
                          I agree to the terms and conditions and to receive
                          text messages and phone calls from Advantage2U™ and
                          Ewald Kia. I understand that no consent to texting or
                          calling is required for a purchase of a product or a
                          service. Please <TermsConditions show={true} /> of
                          Use.{" "}
                        </label>
                      }
                      onChange={(e, value) =>
                        onEditInput("agreement", value.checked)
                      }
                      error={
                        !errors.agreement
                          ? "Please, accept the terms and conditions"
                          : null
                      }
                    />
                  </Form.Group>

                  <div
                    style={{ width: "100%", textAlign: "center" }}
                    className={"continueButtonWrapper"}
                  >
                    <Button disabled={disabledForm} onClick={signUpButton}>
                      Get Pre-Approved & Create My Vault
                    </Button>
                  </div>
                  <h2 className="title-or">OR</h2>
                  <h2 className="title" onClick={() => setLogin(true)}>
                    <p>Log-in with your</p>{" "}
                    <p>Current MyPaymentPower Profile</p>
                  </h2>
                </div>
              ) : (
                <Login
                  prequalified
                  updateUser={props.updateUser}
                  back={() => setLogin(false)}
                  getUser={props.CreditSolutionRequest}
                />
              )}
            </Form>
          )}

          {validCode && (
            <CodeModal
              register={true}
              email={form.email}
              password={form.password}
              phone={form.phone_number}
              updateCode={onEditInput}
              action={signUpButton}
              hideModalCode={() => {
                setvalidCode(false);
                setDisabledForm(false);
              }}
              disabled={disabledForm}
            />
          )}
          {modal.message && (
            <ModalComponent
              hideModal={() => {
                setModal({ modal: {} });
                setDisabledForm(false);
              }}
              modal={modal}
            />
          )}
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button
            buttonStyle={"danger"}
            className="actionButton"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          {!register && (
            <Button
              className="continueButton"
              onClick={props.CreditSolutionRequest}
              disabled={disabled}
            >
              Continue
            </Button>
          )}
        </Modal.Actions>
      </Modal>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};
