import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import {
  infoPaymentBenefit,
  FormatNumberToPrice,
  infoWeeklyPayment,
  infoWeeklyPlusPayment,
  purchasingPowerInfo,
  returnInfoPayments,
} from "../../../helpers/general";
import Buttons from "../payments/Buttons";

import MyPaymentPowerIcon from "../../../../images/MyPayPow.png";

import "./mobilePayments.sass";
import "../payments/Buttons/Buttons.sass";
import "../payments/payment.sass";

const MobilePayment = (props) => {
  const handleOnSelectPaymentPlan = (payment) => {
    props.selectedPayment(
      payment,
      payment !== "monthly" ? "plan" : false,
      props.car
    );
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={"tableWrapper"}>
      <table className={"table"}>
        <thead className={"tableHead"}>
          <tr>
            <th></th>
            <th>
              <button
                className={"paymentPlanSelector"}
                onClick={() => handleOnSelectPaymentPlan("monthly")}
              >
                <span>Banker's Preferred Repayment Plan</span>

                <Popup
                  wide={60}
                  content={returnInfoPayments("monthly")}
                  trigger={
                    <Icon
                      link
                      name="info circle"
                      style={{ marginLeft: "10px" }}
                      onClick={handleIconClick}
                    />
                  }
                  position={"top right"}
                />
              </button>
            </th>
            <th>
              <button
                className={"paymentPlanSelector"}
                onClick={() => handleOnSelectPaymentPlan("weekly")}
              >
                <span>
                  My Payment Power<sup>TM</sup> Repayment Plan
                </span>

                <Popup
                  wide={60}
                  content={returnInfoPayments("weekly")}
                  trigger={
                    <Icon
                      link
                      name="info circle"
                      style={{ marginLeft: "10px" }}
                      onClick={handleIconClick}
                    />
                  }
                  position={"top right"}
                />
              </button>
            </th>
            <th>
              <button
                className={"paymentPlanSelector"}
                onClick={() => handleOnSelectPaymentPlan("weeklyPlus")}
              >
                <span>
                  My Payment Power<sup>TM</sup> Repayment Plan
                </span>

                <Popup
                  wide={60}
                  content={returnInfoPayments("weeklyPlus")}
                  trigger={
                    <Icon
                      link
                      name="info circle"
                      style={{ marginLeft: "10px" }}
                      onClick={handleIconClick}
                    />
                  }
                  position={"top right"}
                />
              </button>
            </th>
          </tr>
        </thead>

        <tbody className={"tableBody"}>
          <tr>
            <td>Payment plan</td>
            <td>
              <span>Monthly payment</span>
            </td>
            <td>
              <div>
                <span>Weekly</span>

                <img
                  src={MyPaymentPowerIcon}
                  alt="Weekly icon"
                  className={"paymentImage"}
                />

                <Popup
                  content={infoWeeklyPayment()}
                  trigger={<Icon link name="info circle" />}
                  position={"top right"}
                />
              </div>
            </td>
            <td>
              <div>
                <span>Weekly</span>

                <img
                  src={MyPaymentPowerIcon}
                  alt="Weekly Plus icon"
                  className={"paymentImage"}
                />

                <Popup
                  content={infoWeeklyPlusPayment()}
                  trigger={<Icon link name="info circle" />}
                  position={"top right"}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Payments amount</td>
            <td>
              <span>
                12 payments in 12 months
                <br />
                <small>(Greater Equity Risk)</small>
              </span>
            </td>
            <td>
              <span>
                Makes <strong className={"highlighted"}>13</strong> monthly
                payments In 12 months
              </span>
            </td>
            <td>
              <span>
                Makes <strong className={"highlighted"}>14</strong> monthly
                payments in 12 months
              </span>
            </td>
          </tr>

          <tr>
            <td>Payment</td>
            <td>{FormatNumberToPrice(props.quots["monthly"].payment)}</td>
            <td>
              {FormatNumberToPrice(props.quots["weekly"].payment)}
              <Popup
                position={"top right"}
                content={
                  <div>
                    {FormatNumberToPrice(props.quots["weekly"].payment) +
                      " = " +
                      FormatNumberToPrice(
                        props.quots["weekly"].paymentNoFeeWeek
                      ) +
                      " (Loan)" +
                      " + " +
                      FormatNumberToPrice(props.quots["weekly"].debitFeeWeek) +
                      " (Program Fee)"}
                  </div>
                }
                trigger={<Icon link name="info circle" />}
              />
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weeklyPlus"].payment)}
              <Popup
                position={"top right"}
                content={
                  <div>
                    {FormatNumberToPrice(props.quots["weeklyPlus"].payment) +
                      " = " +
                      FormatNumberToPrice(
                        props.quots["weeklyPlus"].paymentNoFeeWeek
                      ) +
                      " (Loan)" +
                      " + " +
                      FormatNumberToPrice(
                        props.quots["weeklyPlus"].debitFeeWeek
                      ) +
                      " (Program Fee)"}
                  </div>
                }
                trigger={<Icon link name="info circle" />}
              />
            </td>
          </tr>

          <tr>
            <td>Purchasing power</td>
            <td>
              {FormatNumberToPrice(props.quots["monthly"].purchasing_power)}
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weekly"].purchasing_power)}

              <Popup
                wide
                position={"left center"}
                content={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: purchasingPowerInfo(props.quots, "weekly"),
                    }}
                  ></span>
                }
                trigger={<Icon link name="info circle" />}
              />
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weeklyPlus"].purchasing_power)}

              <Popup
                wide
                position={"left center"}
                content={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: purchasingPowerInfo(props.quots, "weeklyPlus"),
                    }}
                  ></span>
                }
                trigger={<Icon link name="info circle" />}
              />
            </td>
          </tr>

          <tr>
            <td>Term Reduction</td>
            <td>{props.quots["monthly"].term_reduction}</td>
            <td>{props.quots["weekly"].term_reduction}</td>
            <td>{props.quots["weeklyPlus"].term_reduction}</td>
          </tr>

          <tr>
            <td>Contracted Term</td>
            <td>{props.quots["monthly"].contracted_term}</td>
            <td>{props.quots["weekly"].contracted_term}</td>
            <td>{props.quots["weeklyPlus"].contracted_term}</td>
          </tr>

          <tr>
            <td>Effective Loan And Term</td>
            <td>{props.quots["monthly"].effective_term}</td>
            <td>{props.quots["weekly"].effective_term}</td>
            <td>{props.quots["weeklyPlus"].effective_term}</td>
          </tr>

          <tr>
            <td>Payment Benefit Accelerated by</td>
            <td>
              {FormatNumberToPrice(props.quots["monthly"].payment_benefit)}
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weekly"].payment_benefit)}

              <Popup
                hideOnScroll={true}
                position={"top right"}
                content={infoPaymentBenefit()}
                trigger={<Icon link name="info circle" />}
              />
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weeklyPlus"].payment_benefit)}

              <Popup
                hideOnScroll={true}
                position={"top right"}
                content={infoPaymentBenefit()}
                trigger={<Icon link name="info circle" />}
              />
            </td>
          </tr>

          <tr>
            <td>Interest Charges Reduced by</td>
            <td>
              {FormatNumberToPrice(props.quots["monthly"].interest_charges)}
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weekly"].interest_charges)}
            </td>
            <td>
              {FormatNumberToPrice(props.quots["weeklyPlus"].interest_charges)}
            </td>
          </tr>

          <tr>
            <td>Late Payment Fees</td>
            <td>Unprotected</td>
            <td>MPP Protects Against</td>
            <td>MPP Protects Against</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <div
                className={
                  "paymentsBorder paymentOptions paymentPlanButtonsWrapper"
                }
              >
                <Buttons
                  popupPosition={"top right"}
                  selectedPayment={props.selectedPayment}
                  payment={"monthly"}
                  car={props.car}
                  isSlidersFlow={props.isSlidersFlow}
                  preQualifiedForm={props.preQualifiedForm}
                  openSession={props.openSession}
                  retailer={props.retailer}
                  money_owed={props.money_owed}
                  finalQuots={props.quots}
                  trade_in={props.trade_in}
                  setQuotationState={props.setQuotationState}
                />
              </div>
            </td>
            <td>
              <div
                className={
                  "paymentsBorder paymentOptions paymentPlanButtonsWrapper"
                }
              >
                <Buttons
                  popupPosition={"top right"}
                  selectedPayment={props.selectedPayment}
                  payment={"weekly"}
                  car={props.car}
                  isSlidersFlow={props.isSlidersFlow}
                  preQualifiedForm={props.preQualifiedForm}
                  openSession={props.openSession}
                  retailer={props.retailer}
                  money_owed={props.money_owed}
                  finalQuots={props.quots}
                  trade_in={props.trade_in}
                  setQuotationState={props.setQuotationState}
                />
              </div>
            </td>
            <td>
              <div
                className={
                  "paymentsBorder paymentOptions paymentPlanButtonsWrapper"
                }
              >
                <Buttons
                  popupPosition={"top right"}
                  selectedPayment={props.selectedPayment}
                  payment={"weeklyPlus"}
                  car={props.car}
                  isSlidersFlow={props.isSlidersFlow}
                  preQualifiedForm={props.preQualifiedForm}
                  openSession={props.openSession}
                  retailer={props.retailer}
                  money_owed={props.money_owed}
                  finalQuots={props.quots}
                  trade_in={props.trade_in}
                  setQuotationState={props.setQuotationState}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MobilePayment;
