// @flow 
import React from 'react';
import { Table } from 'semantic-ui-react';

export const VehicleInformation = ({ car, retailer }) => {
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              VEHICLE INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell>
              VIN:
            </Table.Cell>
            <Table.Cell>
              {car?.vin}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Year, Make, Model:
            </Table.Cell>
            <Table.Cell>
              {car ? car?.year + " " + car?.make + " " + car?.model : null}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Dealership:
            </Table.Cell>
            <Table.Cell>
              {retailer?.name}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
        <strong>Summary of Service:</strong> My Payment Power. is a payment, planning and protection service.
        Powerful tools from our partners below are included so that you can obtain your
        credit score, enroll in credit monitoring with instant alerts to protect you from
        identity theft and monitor the value and safety of your vehicle with appraisal tools
        and Service Recall alerts.
      </p>
    </div>
  );
};