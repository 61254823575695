import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { QuickStartIncentivizeProgram } from "./quickStartIncentivizeProgram/QuickStartIncentivizeProgram"
import { DealerAgreement } from "./dealerAgreement/DealerAgreement"
import { FrequentlyAskedQuestions } from "./frequentlyAskedQuestions/FrequentlyAskedQuestions"
import { DebitAgreement } from "./debitAgreement/DebitAgreement"

export const Documents = ({ customer, plan, payments, amortization, fee, open, quote, retailer, data, closeDocuments }) => {
  const [step, setStep] = useState(0);
  const [edabled, setEdabled] = useState(true)

  // -------------------------- reset scroll ---------------------------------
  const scrollReset = () => {
    var myDiv = document.getElementsByClassName('documents')[0];
    myDiv.scrollTop = 0;
  }

  const nextButton = () => {
    scrollReset();
    let auxStep = step + 1;
    if (auxStep === 10) {
      setEdabled(false);
    }
    setStep(auxStep);
  }

  const previousButton = () => {
    scrollReset();
    let auxStep = step - 1;
    if (auxStep === 10) {
      setEdabled(false);
    }
    setStep(auxStep);
  }

  return (
    <Modal size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false} >
      <Modal.Header>
        Contract
      </Modal.Header>
      <Modal.Content scrolling={true} className="documents">
        {
          (step === 0) && (
            <QuickStartIncentivizeProgram plan={plan} />
          )
        }
        {
          (step === 1 || step === 2) && (
            <DealerAgreement fee={fee} step={step} retailer={retailer} />
          )
        }
        {
          (step === 3 || step === 4) && (
            <FrequentlyAskedQuestions plan={plan} step={step} />
          )
        }
        {
          (step === 5 || step === 6 || step === 7 || step === 8 || step === 9 || step === 10) && (
            <DebitAgreement customer={customer} plan={plan} payments={payments} amortization={amortization} fee={fee}
              quote={quote} retailer={retailer} data={data} step={step}
            />
          )
        }
      </Modal.Content>
      <Modal.Actions>
        {
          step > 0 && (
            <Button primary onClick={previousButton}>Previous</Button>
          )
        }

        {
          step < 10 && (
            <Button primary onClick={nextButton} >Next</Button>
          )
        }
        <Button secondary onClick={() => closeDocuments(false)} >
          Close
        </Button>
        <Button color='green' onClick={() => closeDocuments(true)} disabled={edabled}>
          Accept
        </Button>
      </Modal.Actions>
    </Modal>
  );
};