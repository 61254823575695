import React from "react";
import cn from "classnames";
import { Button as SemanticButton } from "semantic-ui-react";

import "./Button.sass";

const Button = ({ buttonStyle = "primary", className, children, ...rest }) => {
  return (
    <div
      className={cn(
        "customButtonWrapper",
        buttonStyle === "skyblue" && "skyblue",
        buttonStyle === "secondary" && "secondary",
        buttonStyle === "primary" && "primary",
        buttonStyle === "danger" && "red",
        buttonStyle === "brown" && "brown",
        buttonStyle === "grey" && "grey",
        className
      )}
    >
      <SemanticButton {...rest}>{children}</SemanticButton>
    </div>
  );
};

export default Button;
