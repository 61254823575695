import React from "react";
import { Input as SemanticInput } from "semantic-ui-react";
import cn from "classnames";

import "./styles.sass";

const Input = ({ className, error, disabled, noMargin, ...rest }) => {
  return (
    <div
      className={cn(
        "customInputWrapper",
        error && "danger",
        disabled && "ghost",
        noMargin && "withoutMargin",
        className
      )}
    >
      <SemanticInput disabled={disabled} {...rest} />

      <p className={cn("errorMessage", error && "errorMessageVisible")}>
        {error}
      </p>
    </div>
  );
};

export default Input;
