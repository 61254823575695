import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { PaymentPlans } from "./PaymentPlans";

import Button from "../../global/Button";

export const PaymentPlansModal = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      size="large"
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      trigger={
        <Button buttonStyle={"skyblue"} style={{ minWidth: "100px" }} onClick={() => setOpen(true)}>
          Payment Plans
        </Button>
      }
    >
      <Modal.Header>{item.name + " " + item.second_name}</Modal.Header>
      <Modal.Content className="loanDetails" scrolling>
        <PaymentPlans customer={item} type={"admin"} />
      </Modal.Content>
      <Modal.Actions className={"actionButtons"}>
        <Button
          className={"actionButton"}
          buttonStyle={"danger"}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
