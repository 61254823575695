// IMPORT LIBRARIES/EXTERNAL COMPONENTS
import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import Dashboard from "./Dashboard";

// IMPORT LOCAL COMPONENTS
import UserMenu from "../utilities/menu/menu";
import UserHeader from "../utilities/userHeader/userHeader";
import ListQuotes from "../utilities/ListQuotes/listQuotes";
import ListBuyers from "../utilities/listBuyers/listBuyers";
import RangeValues from "../utilities/rangeValues/rangeValues";

// IMPORT HELPERS
import { retailerMenu } from "../helpers/menus";
import { FormatChartData } from "../helpers/general";

// REQUEST FUNCTIONS
import {
  retailerStatusQuotes,
  searchQuotesRetailer,
  retailerData,
  getCustomers,
} from "../utilities/requests";

// STYLES AN IMAGES
import background from "../../images/background-chromacars.png";
import { Inventory } from "../utilities/inventory/Inventory";
import { BackMenu } from "../utilities/menu/BackMenu";
import { CreditSolutionRequests } from "../utilities/creditSolutionRequests/CreditSolutionRequests";

class Retailer extends Component {
  constructor(props) {
    super(props);
    // Sets the range for the date buttons.
    // today: current date
    // start: six months ago from now.
    const today = new Date();
    let start = new Date();
    start = new Date(start.setMonth(start.getMonth() - 6));

    this.state = {
      loading: false,
      activeItem: "dashboard",
      carIdByUrl: null,
      chartDateStart: start,
      chartDateEnd: today,
      chartData: null,
      lastQuotes: [],
      buyers: {
        data: [],
        total: 0,
      },
      // ------------------------ quotes --------------------------
      quotes: {
        data: [],
        last_page: 1,
      },
      page: 1,
      totalPages: 1,
      // ----------------------- current user ---------------------
      retailer: {
        credit_range: {},
        quots_count: 0,
        last_week: 0,
      },
      menuOpened: false,
    };
    this.changeActiveItem = this.changeActiveItem.bind(this);
    this.searchStatusQuotes = this.searchStatusQuotes.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.changeDefaultId = this.changeDefaultId.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    retailerData()
      .then((response) => {
        console.log("Retailer component data: ", response);
        const recent = response.data.quotes.data.slice(0, 5);
        let chartData = FormatChartData(response.data.getStatusQuotes);
        this.setState({
          loading: false,
          retailer: response.data.retailer,
          buyers: response.data.customers,
          quotes: response.data.quotes,
          lastQuotes: recent,
          chartData,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  onEditInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  changeActiveItem(selected) {
    this.setState({ activeItem: selected });
  }

  searchStatusQuotes() {
    // Sets the parameters for the status quotes search.
    const params = {
      startDate: `${this.state.chartDateStart.getFullYear()}-${
        this.state.chartDateStart.getMonth() + 1
      }-${this.state.chartDateStart.getDate()}`,
      endDate: `${this.state.chartDateEnd.getFullYear()}-${
        this.state.chartDateEnd.getMonth() + 1
      }-${this.state.chartDateEnd.getDate()}`,
    };
    //Gets the retailer oupts status, grouped by month and year
    retailerStatusQuotes(params)
      .then((response) => {
        // Sends the data to "FormatChartData" to give the correct JSON structure for the bar chart (chart.js)
        let chartData = FormatChartData(response.data);
        this.setState({ chartData: chartData });
      })
      .catch((error) => {});
  }

  // ------------------------------------- get buyers list paginate ------------------------------------------------
  changePageBuyers = (
    page = 1,
    startDate = null,
    endDate = null,
    search = null
  ) => {
    let params = { page };
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (search) params["search"] = search;
    this.setState({ loading: true });
    getCustomers(params)
      .then((response) => {
        this.setState({
          buyers: response.data,
          loading: false,
        });
      })
      .catch((error) => console.log(error));
  };

  // ----------------------------------- get quotes list paginate ---------------------------------------------------
  changePage = (page = 1, startDate = null, endDate = null, search = null) => {
    let params = { page };
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (search) params["search"] = search;
    this.setState({ loading: true });
    searchQuotesRetailer(params)
      .then((response) => {
        this.setState({
          quotes: response.data,
          // page:       response.data.current_page,
          // totalPages: response.data.last_page,
          loading: false,
        });
      })
      .catch((error) => console.log(error));
  };

  onEditDate(date, id) {
    this.setState({ [id]: date }, this.searchStatusQuotes);
  }

  showDetails(id) {
    this.setState({ carIdByUrl: id, activeItem: "quotes" });
  }

  changeDefaultId(id) {
    this.setState({ carIdByUrl: id });
  }
  // ---------------------update retailer ranges ------------------
  updateRetailer = (updated) => {
    let retailer = this.state.retailer;
    retailer = updated;
    this.setState({ retailer });
  };

  // ----------------------- update status of quotes ------------
  updateCreatedQuots = (status, id) => {
    let quotes = this.state.quotes;
    for (let i = 0; i < quotes.data.length; i++) {
      if (quotes.data[i].id == id) {
        quotes.data[i]["status"] = status;
        break;
      }
    }
    this.setState({ quotes });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <div className={"dashboard"}>
        <UserMenu
          handleClick={this.changeActiveItem}
          activeDefault={this.state.activeItem}
          elements={retailerMenu}
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />

        <BackMenu
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />

        <div className={"dashboardContent"} style={{ background: "white" }}>
          <UserHeader
            title={"SECTION TITLE"}
            name={this.state.retailer.name}
            openMenu={() => this.setState({ menuOpened: true })}
          />

          {activeItem === "dashboard" && (
            <Dashboard
              state={{ ...this.state }}
              changeActiveItem={this.changeActiveItem}
              onEditDate={this.onEditDate}
              showDetails={this.showDetails}
            />
          )}

          {
            <ListBuyers
              buyers={this.state.buyers.data}
              totalPages={this.state.buyers.last_page}
              changePage={this.changePageBuyers}
              isActive={activeItem === "buyers"}
              role={"retailer"}
            />
          }

          {
            <ListQuotes
              role={"retailer"}
              quotes={this.state.quotes.data}
              totalPages={this.state.quotes.last_page}
              changePage={this.changePage}
              defaultCar={this.state.carIdByUrl}
              changeDefault={this.changeDefaultId}
              isActive={activeItem === "quotes"}
              updateCreatedQuots={this.updateCreatedQuots}
            />
          }

          {activeItem === "ranges" && (
            <RangeValues
              retailer={this.state.retailer}
              updateRetailer={this.updateRetailer}
            />
          )}

          {activeItem === "inventory" && <Inventory userType={"retailer"} />}

          {activeItem === "creditRequests" && <CreditSolutionRequests />}
        </div>

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </div>
    );
  }
}

export default Retailer;
