import React, { useState } from "react";

import { FormatDate } from "../../../helpers/general";
import { LoanDetails } from "../../loanDetails/LoanDetails";

import NoImageAvailable from "../../../../images/no-image-available.png";

import "./QuotCard.sass";

const QuotCard = ({ quot, role, onClick }) => {
  const [previewPhoto, setPreviewPhoto] = useState(
    JSON.parse(quot.car.images)[0]
  );

  return (
    <div className="quotCardWrapper" onClick={onClick}>
      <div className={"carImageWrapper"}>
        <img
          src={previewPhoto}
          alt={"Car photo"}
          onError={(e) => setPreviewPhoto(NoImageAvailable)}
        />
      </div>

      <div className={"quotInfoWrapper"}>
        <ul className={"quotInfo"}>
          <li className={"listItem nameWrapper"}>
            <span
              className={"carName"}
            >{`${quot.car.make} ${quot.car.model} ${quot.car.year}`}</span>

            <span>Stock #{quot.car.stock_number}</span>
          </li>

          <li className={"listItem upperInfo"}>
            <span>
              {`${
                role == "customer" || role == "admin" ? "Retailer" : "Customer"
              }:`}
            </span>

            <span>
              {role == "customer" || role == "admin"
                ? quot.retailer.name
                : quot.customer.name + " " + quot.customer.second_name}
            </span>
          </li>

          <li className={"listItem lowerSection"}>
            <span>{FormatDate(quot.created_at)}</span>

            <span>
              {quot.status == 0
                ? " Pending"
                : quot.status == 1
                ? " Created"
                : quot.status == 2
                ? " Accepted"
                : quot.status == 3
                ? " Cancelled"
                : quot.status == 4
                ? " Expired"
                : null}
            </span>
          </li>

          {(role === "customer" || role === "retailer") && (
            <li className={"listItem loanDetailsButton"}>
              {role === "customer" ? (
                quot.payment_plan ? (
                  <LoanDetails
                    id={quot.payment_plan?.id}
                    quote={{ id: quot.id, car: quot.car }}
                    customer={quot.user}
                    type={"customer"}
                    retailer={quot.retailer}
                  />
                ) : !quot.customer.account ? (
                  "Funding source is not added"
                ) : quot.customer.account.verified ? (
                  <LoanDetails
                    id={quot.payment_plan?.id}
                    quote={{ id: quot.id, car: quot.car }}
                    customer={quot.customer}
                    type={"customer"}
                    retailer={quot.retailer}
                  />
                ) : (
                  "Funding source is not verified"
                )
              ) : (
                <LoanDetails
                  id={quot.payment_plan?.id}
                  quote={{ id: quot.id, car: quot.car }}
                  customer={quot.customer}
                  type={"retailer"}
                  retailer={quot.retailer}
                />
              )}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default QuotCard;
