import React from 'react';
import { Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../../helpers/general';

export const FeeInformation = ({ plan, fee }) => {
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              FEE DISCLOSURE AND ESTIMATED TOTAL COSTS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              One time Fees:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Enrollment Fee:
            </Table.Cell>
            <Table.Cell>
              $0.00
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              1x Account/Identity Verification Fee Via Plaid:
            </Table.Cell>
            <Table.Cell>
              {FormatNumberToPrice(3)}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              Recurring Fees:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              ACH/Convenience Fee (per debit):
            </Table.Cell>
            <Table.Cell>
              Weekly {FormatNumberToPrice(fee)}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              Fees over Loan Term:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2'>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
      There are NEVER any enrollment fees or hidden costs with My Payment
      Power. You may elect to cancel the weekly repayment program at any
      time with no cancellation fee. However, any weekly processing fees that
      My Payment Power has collected cannot be refunded. Cancellation can
      be activated inside your My Payment Power Vehicle Repayment Vault
      available 24/7 at mypaymentpower.com.
      </p>
    </div>
  );
};