import React, { useEffect } from "react";
import cn from "classnames";
import { Popup } from "semantic-ui-react";
import { useSwiper } from "swiper/react";
import ListItem from "./ListItem";
import Button from "../../../../global/Button";

import Full from "../../../../../images/fullProtection.svg";
import Base from "../../../../../images/baseProtection.svg";
import Standard from "../../../../../images/standardProtection.svg";
import { ReactComponent as ShoppingCartIcon } from "../../../../../images/cart.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/info-icon.svg";

import { formatterPrice } from "../../../../helpers/general";

import "./SwiperCard.sass";

const SwiperCard = ({
  isActive,
  isPrev,
  isNext,
  isSelected,
  style,
  item,
  selectedPlan,
  onClick,
}) => {
  const isNeighborСard = isPrev || isNext;
  const isVisible = isNeighborСard || isActive;

  const swiper = useSwiper();

  const images = {
    full: Full,
    standard: Standard,
    base: Base,
  };

  const prices = {
    full: {
      60: {
        weekly: 10,
        monthly: 40,
      },
      72: {
        weekly: 7.75,
        monthly: 33,
      },
    },
    standard: {
      60: {
        weekly: 7.5,
        monthly: 30,
      },
      72: {
        weekly: 5.75,
        monthly: 23,
      },
    },
    base: {
      60: {
        weekly: 5,
        monthly: 18,
      },
      72: {
        weekly: 3.75,
        monthly: 15,
      },
    },
  };

  useEffect(() => {}, []);

  return (
    <div
      className={cn(
        "swiperCardWrapper",
        isVisible && "visible",
        !isActive && "decreased",
        isSelected && "selected",
        selectedPlan && !isSelected && "notSelected"
      )}
      style={style}
      onClick={() => {
        onClick(isSelected ? null : item.type);

        isNext && swiper.slideNext();
        isPrev && swiper.slidePrev();
      }}
    >
      <div className={"cardContent"}>
        <div className={"cardHeader"}>
          <h3 className={"title"}>{item.type}</h3>

          <span className={"subtitle"}>coverage</span>

          <Popup
            disabled={isNext || isPrev}
            trigger={<InfoIcon className={"popupIcon"} />}
            position={"top right"}
            content={
              <div className={"popupContent"}>
                <span>
                  To select or disable Protective Coverage Plan, click on the
                  card
                </span>
              </div>
            }
          />
        </div>

        <div className={"cardDivider"} />

        <div className={"imageWrapper"}>
          <img src={images[item.type]} />
        </div>

        <div className={"cardListWrapper"}>
          <ul className={"cardList"}>
            <ListItem
              label={item.type === "base" ? "Powertrain" : "Service Plan"}
              isSupported={true}
            />

            <ListItem label={"GAP Plus"} isSupported={item.type !== "base"} />

            <ListItem
              label={"Paint Sealant"}
              isSupported={item.type !== "base"}
            />

            <ListItem label={"Tire & Wheel"} isSupported={true} />

            <ListItem
              label={"Pre-paid Maintenance"}
              isSupported={item.type === "full"}
            />

            <ListItem
              label={"Key Replacement"}
              isSupported={item.type === "full"}
            />

            <ListItem
              label={"Theft Registration"}
              isSupported={item.type === "full"}
            />

            <ListItem
              label={"Windshield Replacement"}
              isSupported={item.type === "full"}
            />
          </ul>
        </div>

        <div className={"cardDivider marginTop"} />

        <div className={"bottomActions"}>
          <div className={"record"}>
            <div>
              <div className={"vertical"}>
                <span className={"label grey"}>Monthly</span>

                <span className={"value grey"}>
                  {formatterPrice.format(prices[item.type][60].monthly)}
                </span>
              </div>

              <div className={"vertical"}>
                <span className={"label"}>Weekly</span>

                <span className={"value"}>
                  {formatterPrice.format(prices[item.type][60].weekly)}
                </span>
              </div>

              <div className={"vertical"}>
                <span className={"percent"}>4,99%</span>

                <span className={"value"}>60/mo</span>
              </div>
            </div>

            <Button icon className={"shoppingCartWrapper"}>
              <ShoppingCartIcon />
            </Button>
          </div>

          <div className={"record"}>
            <div>
              <div className={"vertical"}>
                <span className={"label grey"}>Monthly</span>

                <span className={"value grey"}>
                  {formatterPrice.format(prices[item.type][72].monthly)}
                </span>
              </div>

              <div className={"vertical"}>
                <span className={"label"}>Weekly</span>

                <span className={"value"}>
                  {formatterPrice.format(prices[item.type][72].weekly)}
                </span>
              </div>

              <div className={"vertical"}>
                <span className={"percent"}>5,99%</span>

                <span className={"value"}>72/mo</span>
              </div>
            </div>

            <Button icon className={"shoppingCartWrapper"}>
              <ShoppingCartIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperCard;
