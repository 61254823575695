import moment from "moment";
import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";

import YearInput from "../../global/YearInput";
import DateInput from "../../global/DateInput";
import Dropdown from "../../global/DropDown";
import Input from "../../global/Input";

export const FilterSection = (props) => {
  const { options } = props;

  const [form, setForm] = useState({
    year: "",
    make: "",
    status: "",
    condition: "",
    stock_date_1: "",
    stock_date_2: "",
    keyword: "",
  });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    props.search(auxForm);
    setForm({ ...auxForm });
  };

  return (
    <Form className="search-form filters-form">
      <div>
        <Form.Field width={5}>
          <label>Year:</label>

          <YearInput
            maxDate={moment().add(2, "years")}
            placeholder={"Year"}
            onChange={(event, { name, value }) => {
              onEditInput(value, "year");
            }}
            value={form.year}
            popupPosition={"right center"}
            animation="none"
            clearable
          />
        </Form.Field>
      </div>

      <div>
        <Form.Field width={5}>
          <label>Make:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "make")}
            placeholder={"Make"}
            options={options.make}
            value={form.make}
            clearable
            selection
          />
        </Form.Field>
      </div>

      <div>
        <Form.Field width={5}>
          <label>Model:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "model")}
            placeholder={"Model"}
            options={options.model}
            value={form.model}
            clearable
            selection
          />
        </Form.Field>
      </div>

      <div>
        <Form.Field width={5}>
          <label>Vehicle Condition:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "condition")}
            placeholder={"Vehicle Condition"}
            options={options.condition}
            value={form.condition}
            clearable
            selection
          />
        </Form.Field>
      </div>

      <div>
        <Form.Field width={5}>
          <label>Date:</label>

          <Grid>
            <Grid.Column
              width={8}
              style={{ padding: 0 }}
              className={"datePickerContainer"}
            >
              <DateInput
                dateFormat="MM-DD-YYYY"
                onChange={(event, { name, value }) => {
                  onEditInput(value, "stock_date_1");
                }}
                value={form.stock_date_1}
                popupPosition={"right center"}
                animation="none"
                className="datePicker"
                clearable
              />
            </Grid.Column>

            <Grid.Column
              width={8}
              style={{ padding: 0 }}
              className={"datePickerContainer"}
            >
              <DateInput
                dateFormat="MM-DD-YYYY"
                onChange={(event, { name, value }) => {
                  onEditInput(value, "stock_date_2");
                }}
                value={form.stock_date_2}
                popupPosition={"right center"}
                animation="none"
                className="datePicker"
                clearable
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
      </div>

      <div>
        <Form.Field width={5}>
          <label>Keyword:</label>

          <Input
            onChange={(e) => onEditInput(e.target.value, "keyword")}
            placeholder={"Keyword"}
            value={form.keyword}
            clearable
          />
        </Form.Field>
      </div>
    </Form>
  );
};
