import React from "react";
import cn from "classnames";

import { TextArea as SemanticTextArea } from "semantic-ui-react";

import "./styles.sass";

const TextArea = ({ error, disabled, className, ...rest }) => {
  return (
    <div
      className={cn(
        "customTextAreaWrapper",
        disabled && "ghost",
        error && "danger",
        className
      )}
    >
      <SemanticTextArea disabled={disabled} {...rest} />

      <p className={cn("errorMessage", error && "errorMessageVisible")}>
        {error}
      </p>
    </div>
  );
};

export default TextArea;
